import { Config, UIHelper as uh } from 'src/core';
import React, { useState } from 'react';
import { useGetDataSharingList, useUser } from 'src/core/hooks/useUser';
import { useLatestDataCount, useUpdateLatestDataCount } from 'src/core/hooks/useLatestDataCount';

import ActionIcon from 'src/features/menu/components/icons/ActionIcon';
import AssessmentIcon from 'src/features/menu/components/icons/AssessmentIcon';
import BottomMenu from 'src/features/menu/BottomMenu';
import { ErrorModal } from 'src/components/shared/ErrorModal';
import GroupMenu from './GroupMenu';
import { LatestDataCountType } from 'src/core/types/UserAttributes';
import MarkerIcon from 'src/features/menu/components/icons/MarkerIcon';
import ScanIcon from 'src/features/menu/components/icons/ScanIcon';
import { ScrollView } from 'react-native-gesture-handler';
import SpecialGroupMenu from 'src/features/menu/SpecialGroupMenu';
import Spinner from 'react-native-loading-spinner-overlay/lib';
import { StyleSheet } from 'react-native';
import TalkToKaliIcon from 'src/features/menu/components/icons/TalkToKaliIcon';
import { mainMenuContainer } from 'src/core/brands';
import { useAppStore } from 'src/core/store';
import { useGetAppName, useGetChatbotName } from 'src/core/hooks/useBrands';
import { useNavigation } from '@react-navigation/native';
import { useTheme } from '@ui-kitten/components';
import { useTranslationFunc } from 'src/core/hooks/useTranslationFunc';

export type GroupMenuItem = {
  name: string;
  id: string;
  action: () => any;
  hidden: boolean;
  leftIcon?: any;
  count?: number;
  accessoryLeft?: string;
};

//styles
const styleContainer = StyleSheet.create({
  scrollView: {
    flex: 1,
    paddingLeft: uh.w2DP(16),
    paddingRight: uh.w2DP(16),
    paddingTop: uh.h2DP(16),
    paddingBottom: uh.h2DP(20)
  }
});

const MainMenuContainer = () => {
  const appName = useGetAppName();
  const navigation = useNavigation();
  const newAssessmentCount = useAppStore((state) => state.newAssessmentCount);
  const newActionCount = useAppStore((state) => state.newActionCount);
  const newMessageCount = useAppStore((state) => state.newMessageCount);
  const [latestDataCountRefreshing, setLatestDataCountRefreshing] = useState<boolean>(false);
  const [isLatestDataCountLoading, latestDataCountError] = useLatestDataCount({
    refreshing: latestDataCountRefreshing,
    setRefreshing: setLatestDataCountRefreshing
  });

  const [updateLatestDataCount, updateLatestDataCountError] = useUpdateLatestDataCount();
  const th = useTheme();

  const { signOut } = useUser();
  //properties and handler
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const actionsHandler = () => {
    if (newActionCount > 0) {
      updateLatestDataCount(LatestDataCountType.ACTION);
    }
    navigation.navigate('Actions', { screen: 'TodayActions' });
  };
  const scanHandler = () => navigation.navigate('ScanGettingStarted');
  const assessmentsHandler = () => {
    if (newAssessmentCount > 0) {
      updateLatestDataCount(LatestDataCountType.ASSESSMENT);
    }
    navigation.navigate('Assessments');
  };
  const markersHandler = () => {
    updateLatestDataCount(LatestDataCountType.MARKER);
    navigation.navigate('MyMarkers', { screen: 'Favorite' });
  };
  const chatHandler = () => {
    if (newMessageCount > 0) {
      updateLatestDataCount(LatestDataCountType.KALI);
    }
    navigation.navigate('Kali');
  };
  const profileHandler = () => navigation.navigate('ProfileSettings');
  const subscriptionsHandler = () => navigation.navigate('Subscriptions');
  const accountHandler = () => null;
  const dataSharingHandler = () => navigation.navigate('DataSharing');
  const wearablesHandler = () => navigation.navigate('ConnectedDevices');
  const preferencesHandler = () => navigation.navigate('Preferences');
  const developerSettingsHandler = () => navigation.navigate('DeveloperSettings');
  const aboutUsHandler = () => navigation.navigate('AboutUs');
  const feedbackHandler = () => navigation.navigate('Feedback');
  const signOutHandler = signOut;
  const isDataSharingEnabled = useGetDataSharingList().length > 0;

  const handleSignOut = () => {
    setIsLoading(true);
    signOutHandler();
  };

  const t = useTranslationFunc(mainMenuContainer);
  const chatbotName = useGetChatbotName();

  const specialGroupMenus: GroupMenuItem[] = [
    {
      leftIcon: <ActionIcon color={th['color-primary-500']} backgroundColor={th['color-primary-transparent-200']} />,
      name: t('mainMenuActions'),
      action: actionsHandler,
      hidden: false,
      count: newActionCount,
      id: 'menu-item-action'
    },
    {
      leftIcon: <ScanIcon color={th['color-primary-500']} backgroundColor={th['color-primary-transparent-200']} />,
      name: t('mainMenuScan'),
      action: scanHandler,
      hidden: false,
      count: 0,
      id: 'menu-item-scan'
    },
    {
      leftIcon: (
        <AssessmentIcon color={th['color-primary-500']} backgroundColor={th['color-primary-transparent-200']} />
      ),
      name: t('mainMenuAssessments'),
      action: assessmentsHandler,
      hidden: false,
      count: newAssessmentCount,
      id: 'menu-item-assessments'
    },
    {
      leftIcon: <MarkerIcon color={th['color-primary-500']} backgroundColor={th['color-primary-transparent-200']} />,
      name: t('mainMenuMarkersOfInterest'),
      action: markersHandler,
      hidden: false,
      count: 0,
      id: 'menu-item-markers'
    },
    {
      leftIcon: (
        <TalkToKaliIcon color={th['color-primary-500']} backgroundColor={th['color-primary-transparent-200']} />
      ),
      name: t('mainMenuTalkToKali', { chatbot_name: chatbotName }),
      action: chatHandler,
      hidden: false,
      count: newMessageCount,
      id: 'menu-item-chatbot'
    }
  ];
  const groupMenu: GroupMenuItem[] = [
    {
      name: t('mainMenuProfile'),
      action: profileHandler,
      hidden: false,
      id: 'menu-item-profile',
      accessoryLeft: 'person-outline'
    },
    {
      name: t('mainMenuSubscriptions'),
      action: subscriptionsHandler,
      hidden: appName == 'Kalibra' ? false : true,
      id: 'menu-item-subscription',
      accessoryLeft: 'credit-card-outline'
    },
    { name: t('mainMenuAccount'), action: accountHandler, hidden: true, id: 'menu-item-account' },
    {
      name: t('mainMenuDataSharing'),
      action: dataSharingHandler,
      hidden: !isDataSharingEnabled,
      id: 'menu-item-datasharing',
      accessoryLeft: 'people-outline'
    },
    {
      name: t('mainMenuWearables'),
      action: wearablesHandler,
      hidden: false,
      id: 'menu-item-wearables',
      accessoryLeft: 'bluetooth-outline'
    },
    {
      name: t('mainMenuPreferences'),
      action: preferencesHandler,
      hidden: false,
      id: 'menu-item-preferences',
      accessoryLeft: 'color-palette-outline'
    },
    {
      name: t('mainMenuLogOut'),
      action: handleSignOut,
      hidden: false,
      id: 'menu-item-logout',
      accessoryLeft: 'log-out-outline'
    },
    {
      name: t('mainMenuDeveloperSettings'),
      action: developerSettingsHandler,
      hidden: !Config.DEVELOPMENT_MODE,
      id: 'menu-item-devsettings',
      accessoryLeft: 'settings-2-outline'
    }
  ];

  if (isLatestDataCountLoading || isLoading) {
    return <Spinner visible={true} />;
  }

  //view
  return (
    <>
      <ScrollView alwaysBounceVertical={true} style={styleContainer.scrollView}>
        <SpecialGroupMenu items={specialGroupMenus} />
        <GroupMenu items={groupMenu} />
        <BottomMenu onAboutKalibra={aboutUsHandler} onFeedback={feedbackHandler} />
      </ScrollView>
      <ErrorModal
        visible={latestDataCountError != undefined || updateLatestDataCountError != undefined}
        message={{ ...latestDataCountError, ...updateLatestDataCountError }}
      />
    </>
  );
};

export default MainMenuContainer;
