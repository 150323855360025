import { Button, Input, Text, useTheme } from '@ui-kitten/components';
import { StyleSheet, View, ViewProps } from 'react-native';
import { ValidateError, authCodeValidator, UIHelper as uh } from '../../core';

import { AxiosError } from 'axios';
import { BackendApi } from 'src/api/shared';
import { ErrorMessage } from '../shared';
import { ErrorModal } from '../shared/ErrorModal';
import { InfoModal } from '../shared/InfoModal';
import React from 'react';
import Spinner from 'react-native-loading-spinner-overlay/lib';
import { confirmSignUpCode } from '../../api/auth';
import { useAppStore } from 'src/core/store';

interface IRegisterOTPProps extends ViewProps {
  resetActionClickHandler: () => void;
  registerEmail: string;
  step: number;
  setStep: (step: number) => void;
}

const RegisterOTP = (props: IRegisterOTPProps) => {
  // styles
  const th = useTheme();
  const theme = useAppStore((state) => state.theme);
  const condColors = {
    divider: uh.getHex(th, theme, 'color-basic-400', 'color-basic-200'),
    input: uh.getHex(th, theme, 'color-basic-100', 'color-basic-1100')
  };
  const styleContainer = StyleSheet.create({
    resetPassContainer: { marginTop: uh.h2DP(32) },
    input: { backgroundColor: condColors.input, marginBottom: uh.h2DP(36), marginTop: uh.h2DP(8) }
  });

  // properties
  const [oneTimePass, setOneTimePass] = React.useState<string>('');
  const [email, setEmail] = React.useState<string>(props.registerEmail);
  const [error, setError] = React.useState<ValidateError>({ name: '', message: '' });
  const [isSubmitted, setIsSubmitted] = React.useState(false);
  const [apiError, setApiError] = React.useState<AxiosError>({} as AxiosError);
  const [visibleErrorModal, setVisibleErrorModal] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [disabled, setDisabled] = React.useState<boolean>(false);
  const [visibleInfoModal, setVisibleInfoModal] = React.useState<boolean>(false);

  const resendOTPMessage = `A new OTP has been sent to ${email}, if you have not received the OTP in your Inbox, please check your spam and junk folders.`;

  const btnClickHandler = async () => {
    const authCodeError = authCodeValidator(oneTimePass);
    if (authCodeError) {
      setError({ name: 'authCode', message: authCodeError });
      return;
    }
    setIsSubmitted(true);
    // TODO :
    // verify the OTP code then go to ConfirmationRegister screen
    const result = await confirmSignUpCode(email, oneTimePass);
    setIsSubmitted(false);
    if (result?.error) {
      setError({ name: '', message: result?.error?.message });
    } else {
      setError({ name: '', message: '' });
      props.resetActionClickHandler();
    }
  };

  const handleOTPresend = async () => {
    setLoading(true);

    // Disable the resend button for 1min
    setDisabled(true);
    setTimeout(() => {
      setDisabled(false);
    }, 1000 * 60);

    await BackendApi.get(`users/resend-confirmation-code/${email}`)
      .then((res) => {
        if (res.data) {
          setVisibleInfoModal(true);
        } else
          setError({
            name: 'invalidEmail',
            message:
              "This email isn't assigned to any account or is already verified. Try a different email, or sign up instead"
          });
      })
      .catch((err) => {
        setApiError(err);
        setVisibleErrorModal(true);
      });
    setLoading(false);
  };

  // view
  return (
    <View style={[styleContainer.resetPassContainer, props.style]}>
      {loading && <Spinner visible={true} />}
      <ErrorMessage message={error.message} />
      {props.step === 1 ? (
        <Input
          size="large"
          status={error.name == 'email' ? 'danger' : 'basic'}
          style={styleContainer.input}
          label="Email"
          value={email}
          onChangeText={setEmail}
          accessible={true}
          accessibilityLabel="OTP email"
          testID="input_otp_email"
        />
      ) : (
        <Input
          size="large"
          status={error.name == 'authCode' ? 'danger' : 'basic'}
          style={[styleContainer.input]}
          label="One Time Code"
          value={oneTimePass}
          onChangeText={setOneTimePass}
          accessible={true}
          accessibilityLabel="OTP code"
          testID="input_otp_code"
        />
      )}

      <Button
        size="giant"
        status="primary"
        onPress={() => (props.step === 1 ? props.setStep(2) : btnClickHandler())}
        disabled={isSubmitted}
        accessible={true}
        accessibilityLabel="OTP button"
        testID="otp_button">
        <Text status="primary" category="s2">
          Submit
        </Text>
      </Button>

      {props.step === 2 && (
        <Button
          size="giant"
          status="primary"
          appearance="ghost"
          onPress={() => handleOTPresend()}
          disabled={isSubmitted || disabled}
          style={{ marginTop: uh.h2DP(8) }}
          accessible={true}
          accessibilityLabel="Resend OTP button"
          testID="resend_otp_button">
          <Text status="primary" category="s2">
            Resend Code
          </Text>
        </Button>
      )}
      <ErrorModal visible={visibleErrorModal} message={apiError} closeBtnClick={() => setVisibleErrorModal(false)} />
      <InfoModal
        visible={visibleInfoModal}
        message={resendOTPMessage}
        closeBtnClick={() => setVisibleInfoModal(false)}
      />
    </View>
  );
};

export default RegisterOTP;
