import Animated, { useAnimatedStyle, useSharedValue, withTiming } from 'react-native-reanimated';
import { Button, Text, useTheme } from '@ui-kitten/components';
import React, { useEffect, useState } from 'react';
import { StyleSheet, View, ViewProps } from 'react-native';
import { useGetAppName, useGetAppWebsite, useGetChatbotName } from 'src/core/hooks/useBrands';

import Logo from 'src/core/constants/Logo';
import MarkdownText from '../kalichat/MarkdownText';
import { preSignUpText } from 'src/core/brands';
import { UIHelper as uh } from '../../core';
import { useTranslationFunc } from 'src/core/hooks/useTranslationFunc';

interface PreSignUpProps extends ViewProps {
  finalActionHandler: () => void;
}

const PreSignUp = ({ finalActionHandler }: PreSignUpProps) => {
  //TODO: fetch data, call useEffect add Json call for Data and UseEffect Hook and add loading screen

  //styles
  const th = useTheme();
  const t = useTranslationFunc(preSignUpText);
  const appWebsite = useGetAppWebsite();
  const appName = useGetAppName();
  const chatbotName = useGetChatbotName();

  const kalibraCollection = [
    {
      id: 1,
      title: t('collection1Title'),
      testID: 'preregister-first-title',
      description: t('collection1Description', { app_name: appName, chatbot_name: chatbotName }),
      buttonTitle: t('collection1Button')
    },
    {
      id: 2,
      title: t('collection2Title', { chatbot_name: chatbotName }),
      testID: 'preregister-second-title',
      description: t('collection2Description', {
        app_name: appName,
        app_website: appWebsite,
        chatbot_name: chatbotName
      }),
      buttonTitle: t('collection2Button', { chatbot_name: chatbotName })
    }
  ];

  const styleContainer = StyleSheet.create({
    imageContainer: { maxWidth: '100%', alignItems: 'center', alignContent: 'center', justifyContent: 'center' },
    spinnerContainer: {
      flex: 1,
      maxWidth: '100%',
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: 5
    },
    image: { maxWidth: uh.h2DP(148), height: uh.h2DP(144) },
    imageTitle: {
      textAlign: 'center',
      paddingTop: uh.h2DP(32),
      paddingBottom: uh.h2DP(24)
    },
    imageDescription: { textAlign: 'center', paddingBottom: uh.h2DP(32) },
    button: { width: '100%' }
  });

  const markdownStyles = StyleSheet.create({
    // The main container
    view: {
      fontFamily: 'Poppins-Regular',
      color: th['text-basic-color'],
      fontSize: 14,
      textAlign: 'center',
      marginBottom: 10
    },

    // Emphasis
    strong: {
      fontFamily: 'Poppins-Bold'
    },

    // Links
    autolink: {
      textDecorationLine: 'underline',
      fontFamily: 'Poppins-Bold',
      color: th['text-basic-color']
    },

    // Text Output
    text: {
      textAlign: 'center',
      fontFamily: 'Poppins-Regular',
      color: th['text-basic-color'],
      fontSize: 14
    }
  });

  // properties
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [rerenderKey, setRerenderKey] = useState<number>(0);
  const opacity = useSharedValue(0);

  // handlers and conditionals
  //====> add the api call to get the data here
  const getNextItemHandler = () => {
    if (currentPage === 1) {
      finalActionHandler();
      return;
    }
    setIsLoading(true);
    setCurrentPage((prev) => prev + 1);
    opacity.value = 0;
  };

  const fadeIn = useAnimatedStyle(() => {
    return {
      opacity: withTiming(opacity.value, { duration: 350 })
    };
  });
  const onLoad = () => {
    opacity.value = 1;
    setIsLoading(false);
  };

  // rerender for markdown themes
  useEffect(() => {
    setRerenderKey((prevKey) => prevKey + 1);
  }, [th]);

  // return View
  return (
    <>
      <Animated.View key={kalibraCollection[currentPage].id} style={[styleContainer.imageContainer]}>
        <Logo
          animated={true}
          animatedProps={{ style: [fadeIn, styleContainer.image], resizeMode: 'contain', onLoadEnd: onLoad }}
        />
      </Animated.View>
      <View>
        <Text category="h6" style={styleContainer.imageTitle} testID={kalibraCollection[currentPage].testID}>
          {kalibraCollection[currentPage].title}
        </Text>
        <MarkdownText key={rerenderKey} styles={markdownStyles}>
          {kalibraCollection[currentPage].description}
        </MarkdownText>
        <Button
          style={styleContainer.button}
          size="giant"
          status="primary"
          onPress={getNextItemHandler}
          disabled={isLoading}
          accessible={true}
          accessibilityLabel="Next"
          testID="button_next">
          <Text status="primary" category="s2">
            {kalibraCollection[currentPage].buttonTitle}
          </Text>
        </Button>
      </View>
    </>
  );
};

export default PreSignUp;
