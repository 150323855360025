import { StyleSheet, View, ViewProps } from 'react-native';
import { Text, useTheme } from '@ui-kitten/components';
import { useGetPrivacyPolicy, useGetTermsOfService } from 'src/core/hooks/useBrands';

import React from 'react';
import { textAgreementLink } from 'src/core/brands';
import { UIHelper as uh } from '../../core';
import { useTranslationFunc } from 'src/core/hooks/useTranslationFunc';

//props
interface ITextAgreementLinkProps extends ViewProps {
  goToPage: (url: string) => void;
}

const TextAgreementLink = (props: ITextAgreementLinkProps) => {
  //style
  const th = useTheme();
  const t = useTranslationFunc(textAgreementLink);
  const termsOfService = useGetTermsOfService();
  const privacyPolicy = useGetPrivacyPolicy();

  const styleContainer = StyleSheet.create({
    textCenter: { alignItems: 'center' },
    clickBtn: { alignSelf: 'center' },
    textAlign: { textAlign: 'center' },
    agreementText: {
      marginTop: uh.h2DP(8),
      marginBottom: uh.h2DP(20),
      textAlign: 'center'
    },
    agreementLink: {
      color: th['color-primary-500']
    }
  });

  //view
  return (
    <View style={[props.style, { marginVertical: 8 }]}>
      <Text style={styleContainer.agreementText} category="c2" appearance="hint">
        By signing up you agree to our
        <Text
          category="c2"
          appearance="hint"
          onPress={() => props.goToPage(t('termsOfService', { terms_of_service: termsOfService }))}
          style={styleContainer.agreementLink}>
          {' '}
          terms of use{' '}
        </Text>{' '}
        and
        <Text
          category="c2"
          appearance="hint"
          onPress={() => {
            props.goToPage(t('privacyPolicy', { privacy_policy: privacyPolicy }));
          }}
          style={styleContainer.agreementLink}>
          {' '}
          privacy policy
        </Text>{' '}
      </Text>
    </View>
  );
};

export default TextAgreementLink;
