import { Button, Datepicker, Icon, Input, Radio, RadioGroup, Text, useTheme } from '@ui-kitten/components';
import {
  LoginVariant,
  UserAttributes,
  dateValidator,
  emailValidator,
  genderTypeValidator,
  passwordValidator,
  StringHelper as sh,
  stringValidator,
  UIHelper as uh
} from '../../core';
import { Platform, StyleSheet, TouchableOpacity, TouchableWithoutFeedback, View, ViewProps } from 'react-native';
import { ProfileDataFormError, ProfileDataFormHeight } from 'src/core/types/AuthError';
import React, { useEffect, useRef, useState } from 'react';
import { useGetIsAuthLoading, useSetAuthLoading, useUser, validUserInfoResponse } from 'src/core/hooks/useUser';

import { DATE_FORMAT } from 'src/core/constants/date';
import { ErrorMessage } from '../shared';
import { ErrorModal } from '../shared/ErrorModal';
import GenderInfoTooltip from '../shared/GenderInfoTooltip';
import TextAgreementLink from './TextAgreementLink';
import UserService from 'src/api/user';
import moment from 'moment';
import { registerUser } from '../../api/auth';
import { useAnalytics } from 'src/core/hooks/useAnalytics';
import { ApplicationState, useAppStore } from 'src/core/store';
import { useIsFocused } from '@react-navigation/native';
import { Auth } from 'aws-amplify';
import { getTokenRaw } from 'src/core/helpers/AuthHelper';

// props
interface ILogInProps extends ViewProps {
  type: LoginVariant;
  signInUpHandler?: () => void;
  confirmRegisterHandler?: () => void;
  multiTenantHandler?: () => void;
  resetPasswordHandler?: () => void;
  goToPage?: (url: string) => void;
  scrollRef?: React.MutableRefObject<any>;
}

const LogIn = (props: ILogInProps) => {
  // App Context
  const { addAnalyticsLog } = useAnalytics('Login.tsx');
  const setRegisterEmail = useAppStore((state) => state.setRegisterEmail);

  // styles
  const th = useTheme();
  const gender = ['male', 'female'];
  const theme = useAppStore((state) => state.theme);
  const condColors = {
    divider: uh.getHex(th, theme, 'color-basic-400', 'color-basic-200'),
    input: uh.getHex(th, theme, 'color-basic-100', 'color-basic-1100')
  };
  const styleContainer = StyleSheet.create({
    orContainer: { flexDirection: 'row', alignItems: 'center' },
    divider: { flex: 1, backgroundColor: condColors.divider },
    orContent: { paddingHorizontal: uh.h2DP(4) },
    loginContainer: { marginTop: uh.h2DP(16) },
    input: { backgroundColor: condColors.input, marginVertical: uh.h2DP(8) },
    forgotPasswordContainer: { textAlign: 'center', marginTop: uh.h2DP(22) },
    forgotPasswordButton: { alignSelf: 'center' },
    themeContainerRadio: { flexDirection: 'row', alignItems: 'center' },
    gender: { flexDirection: 'row', alignItems: 'center' },
    backdropStyle: { backgroundColor: 'rgba(0, 0, 0, 0.5)' }
  });

  // properties
  const context = props.type === 'Sign Up' ? 'Sign Up' : 'Sign In';
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [fullname, setFullname] = useState<string>('');
  const [nickname, setNickname] = useState<string>('');
  const [secureTextEntry, setSecureTextEntry] = useState(true);
  const [selectedGenderIndex, setSelectedGenderIndex] = useState(0);
  const [birthdate, setBirthdate] = useState<Date>(() => moment('01/01/1980', DATE_FORMAT).toDate());
  const [error, setError] = useState<ProfileDataFormError>({} as ProfileDataFormError);
  const [loginError, setLoginError] = useState<any>();
  const [visibleErrorModal, setVisibleErrorModal] = useState<boolean>(false);
  const [componentHeights, setComponentHeights] = useState<ProfileDataFormHeight>({} as ProfileDataFormHeight);
  const setAuthLoading = useSetAuthLoading();
  const isAuthLoading = useGetIsAuthLoading();

  const scrollRef = useRef(null);
  const inputRefs = useRef<Array<null | View>>([]);
  const isFocused = useIsFocused();

  const { signInByEmail } = useUser();

  useEffect(() => {
    // Reset form error values

    if (isFocused) setError({} as ProfileDataFormError);
  }, [isFocused]);

  // handlers and conditionals
  const toggleSecureEntry = () => {
    setSecureTextEntry(!secureTextEntry);
  };
  const renderIcon = (iconProps: any) => {
    // This is workaround due to "OnPress" translation issue for react native web
    return Platform.OS === 'web' ? (
      <div onClick={toggleSecureEntry}>
        <Icon {...iconProps} name={secureTextEntry ? 'eye-off' : 'eye'} />
      </div>
    ) : (
      <TouchableWithoutFeedback onPress={toggleSecureEntry}>
        <Icon {...iconProps} name={secureTextEntry ? 'eye-off' : 'eye'} />
      </TouchableWithoutFeedback>
    );
  };

  const processSignUp = async () => {
    const newError: ProfileDataFormError = {} as ProfileDataFormError;

    let firstValue: keyof ProfileDataFormHeight | '' = ''; // use this value to scroll to the first error

    const emailError = emailValidator(email);
    if (emailError) {
      newError.email = emailError;
      if (firstValue === '') firstValue = 'email';
    }
    const passwordError = passwordValidator(password);
    if (passwordError) {
      newError.password = passwordError;
      if (firstValue === '') firstValue = 'password';
    }
    const fullnameError = stringValidator('Full Name', fullname);
    if (fullnameError) {
      newError.fullname = fullnameError;
      if (firstValue === '') firstValue = 'fullname';
    }
    const nicknameError = stringValidator('Preferred Name', nickname);
    if (nicknameError) {
      newError.nickname = nicknameError;
      if (firstValue === '') firstValue = 'nickname';
    }
    const genderError = genderTypeValidator(gender[selectedGenderIndex]);
    if (genderError) {
      newError.gender = genderError;
      if (firstValue === '') firstValue = 'gender';
    }
    const birthdateError = dateValidator('YYYY-MM-DD', moment(birthdate).format('YYYY-MM-DD'));
    if (birthdateError) {
      newError.birthdate = birthdateError;
      if (firstValue === '') firstValue = 'birthdate';
    }

    setError(newError);
    if (Object.values(newError).length > 0) {
      props.scrollRef?.current?.scrollTo({
        y: componentHeights[firstValue as keyof ProfileDataFormHeight]
      });
      return;
    }

    setAuthLoading(true);
    // register user
    const result = await registerUser(
      email,
      password,
      fullname,
      nickname,
      gender[selectedGenderIndex],
      moment(birthdate).format('YYYY-MM-DD')
    );

    if (result?.error) {
      setError({ ...error, email: 'User already exists' });
      props.scrollRef?.current?.scrollTo({
        y: componentHeights.email
      });
      setAuthLoading(false);
      return;
    } else {
      // add new user to database
      UserService.addUser({
        cognitoId: result.userSub,
        gender: gender[selectedGenderIndex],
        name: fullname,
        nickname: nickname,
        email: email,
        birthdate: moment(birthdate).format('YYYY-MM-DD')
      })
        .then((res: any) => {
          setRegisterEmail(email);

          if (props.confirmRegisterHandler) {
            props.confirmRegisterHandler();
          } else {
            setLoginError({
              error: "Couldn't create user - props.confirmRegisterHandler is missing",
              response: res
            });
            setVisibleErrorModal(true);
            addAnalyticsLog({
              function: 'processSignUp',
              data: 'props.confirmRegisterHandler is missing',
              logType: 'error'
            });
          }
          setAuthLoading(false);
        })
        .catch((err: any) => {
          setLoginError(err);
          setVisibleErrorModal(true);
          setAuthLoading(false);
        });
    }
  };
  const setUser = useAppStore((state: ApplicationState) => state.setUser);
  const changeUserAttributes = useAppStore((state: ApplicationState) => state.changeUserAttributes);

  const loadUserData = async () => {
    const token = await getTokenRaw();
    const user = await Auth.currentAuthenticatedUser();

    const userInfo: UserAttributes = {
      name: user.attributes.name,
      nickname: user.attributes.nickname,
      email: user.attributes.email,
      gender: user.attributes.gender,
      birthdate: user.attributes.birthdate,
      sub: user.attributes.sub,
      height: undefined,
      weight: undefined,
      smoker: undefined,
      bloodpressureMedication: undefined,
      hypertension: undefined,
      diabetic: undefined,
      activityLevel: undefined
    };

    let savedUser: UserAttributes = userInfo;

    try {
      const response = await UserService.getUserInfo().catch(() => UserService.addUser(userInfo));
      savedUser = validUserInfoResponse(response);
    } catch (err) {
      //TODO add log to Analitics
      console.error('getUserInfo:', err);
      return false;
    }
    setUser(savedUser.userId ?? '', user.attributes.sub, token, user.attributes.email);
    changeUserAttributes({ ...savedUser, ...userInfo });
  };

  const processSignIn = async () => {
    const newError: ProfileDataFormError = {} as ProfileDataFormError;
    const emailError = emailValidator(email);
    if (emailError) {
      newError.email = emailError;
    }
    const passwordError = passwordValidator(password);
    if (passwordError) {
      newError.password = passwordError;
    }

    setError(newError);
    if (Object.values(newError).length > 0) return;

    setAuthLoading(true);

    signInByEmail(email, password)
      .then(() => {
        loadUserData();
      })
      .catch(() => {
        setError({ ...error, login: 'Invalid email or password' });
        setAuthLoading(false);
      });
  };

  //const signInUpBtnHandler = props.type === 'Log In' ? props.signInUpHandler : props.confirmRegisterHandler;
  const signInUpBtnHandler = async () => {
    if (props.type === 'Log In') {
      await processSignIn();
    } else if (props.confirmRegisterHandler) {
      await processSignUp();
    }
  };

  const renderForgetPassword = () => {
    return (
      props.type === 'Log In' &&
      props.resetPasswordHandler && (
        <View style={styleContainer.forgotPasswordContainer}>
          <TouchableOpacity style={styleContainer.forgotPasswordButton} onPress={props.resetPasswordHandler}>
            <Text
              status="primary"
              category="c2"
              accessible={true}
              accessibilityLabel="Forgot password"
              testID="forgot_password_btn">
              Forgot password?
            </Text>
          </TouchableOpacity>
        </View>
      )
    );
  };

  //returned view
  return (
    <View ref={scrollRef} style={props.style}>
      <ErrorMessage message={error.login} />
      <View style={styleContainer.loginContainer}>
        <View
          ref={(el) => (inputRefs.current[0] = el)}
          onLayout={(event) => setComponentHeights({ ...componentHeights, email: event.nativeEvent.layout.y })}>
          <Input
            size="large"
            status={error.email ? 'danger' : 'basic'}
            style={styleContainer.input}
            label="Email"
            value={email}
            onChangeText={setEmail}
            keyboardType={'email-address'}
            autoCapitalize="none"
            autoCorrect={false}
            accessible={true}
            accessibilityLabel={props.type === 'Log In' ? 'Login email' : 'Register email'}
            testID={props.type === 'Log In' ? 'input_login_email' : 'input_register_email'}
          />
        </View>
        <ErrorMessage message={error.email} />

        <View
          ref={(el) => (inputRefs.current[1] = el)}
          onLayout={(event) => setComponentHeights({ ...componentHeights, password: event.nativeEvent.layout.y })}>
          <Input
            style={styleContainer.input}
            status={error.password ? 'danger' : 'basic'}
            size="large"
            label="Password"
            value={password}
            secureTextEntry={secureTextEntry}
            accessoryRight={renderIcon}
            onChangeText={(nextValue: string) => setPassword(nextValue)}
            autoCapitalize="none"
            autoCorrect={false}
            accessible={true}
            accessibilityLabel={props.type === 'Log In' ? 'Login password' : 'Register password'}
            testID={props.type === 'Log In' ? 'input_login_password' : 'input_register_password'}
          />
        </View>
        <ErrorMessage message={error.password} />

        {props.type === 'Sign Up' && (
          <>
            <View
              ref={(el) => (inputRefs.current[2] = el)}
              onLayout={(event) => setComponentHeights({ ...componentHeights, fullname: event.nativeEvent.layout.y })}>
              <Input
                status={error.fullname ? 'danger' : 'basic'}
                style={[styleContainer.input]}
                size="large"
                label="Full Name"
                value={fullname}
                onChangeText={(text: string) => setFullname(text)}
                autoCapitalize="none"
                autoCorrect={false}
                accessible={true}
                accessibilityLabel={'full_name'}
                testID={'input_full_name'}
              />
            </View>
            <ErrorMessage message={error.fullname} />

            <View
              ref={(el) => (inputRefs.current[3] = el)}
              onLayout={(event) => setComponentHeights({ ...componentHeights, nickname: event.nativeEvent.layout.y })}>
              <Input
                style={[styleContainer.input]}
                status={error.nickname ? 'danger' : 'basic'}
                size="large"
                value={nickname}
                label="Preferred Name"
                onChangeText={(text: string) => setNickname(text)}
                autoCapitalize="none"
                autoCorrect={false}
                accessible={true}
                accessibilityLabel={'preferred_name'}
                testID={'input_preferred_name'}
              />
            </View>
            <ErrorMessage message={error.nickname} />

            <View
              style={{ flex: 1, flexDirection: 'row', alignItems: 'center', marginVertical: uh.h2DP(8) }}
              ref={(el) => (inputRefs.current[4] = el)}
              onLayout={(event) => setComponentHeights({ ...componentHeights, gender: event.nativeEvent.layout.y })}>
              <Text category="label" appearance="hint">
                Sex
              </Text>
              <GenderInfoTooltip />
            </View>
            <RadioGroup
              style={styleContainer.themeContainerRadio}
              selectedIndex={selectedGenderIndex}
              onChange={(index) => setSelectedGenderIndex(index)}>
              <Radio status={'primary'} accessible={true} accessibilityLabel={'gender_male'} testID={'radio_male_btn'}>
                <Text category="label" status={'basic'} appearance="hint">
                  {sh.capitalize(gender[0])}
                </Text>
              </Radio>
              <Radio
                status={'primary'}
                accessible={true}
                accessibilityLabel={'gender_female'}
                testID={'radio_female_btn'}>
                <Text category="c1" status={'basic'} appearance="hint">
                  {sh.capitalize(gender[1])}
                </Text>
              </Radio>
            </RadioGroup>
            <ErrorMessage message={error.gender} />

            <View
              ref={(el) => (inputRefs.current[5] = el)}
              onLayout={(event) => setComponentHeights({ ...componentHeights, birthdate: event.nativeEvent.layout.y })}>
              <Datepicker
                size="large"
                status={error.birthdate ? 'danger' : 'basic'}
                backdropStyle={styleContainer.backdropStyle}
                style={[{ marginTop: uh.h2DP(16) }]}
                controlStyle={[styleContainer.input]}
                label="Date of Birth"
                min={new Date('1920-01-01')}
                placement="bottom"
                max={new Date()}
                date={birthdate}
                onSelect={(nextDate) => setBirthdate(nextDate)}
                accessible={true}
                accessibilityLabel={'date_of_birth'}
                testID={'date_of_birth'}
              />
            </View>
            <ErrorMessage message={error.birthdate} />
          </>
        )}

        <Button
          style={{ marginTop: uh.h2DP(16) }}
          size="giant"
          status="primary"
          disabled={isAuthLoading}
          onPress={signInUpBtnHandler}
          accessible={true}
          accessibilityLabel={props.type === 'Log In' ? 'SignIn button' : 'SignUp button'}
          testID={props.type === 'Log In' ? 'signin_button' : 'signup_button'}>
          <Text status="primary" category="s2">
            {context}
          </Text>
        </Button>
        {props.goToPage && <TextAgreementLink goToPage={props.goToPage} />}
        {renderForgetPassword()}
      </View>
      <ErrorModal
        visible={visibleErrorModal}
        closeBtnClick={() => {
          setVisibleErrorModal(false);
        }}
        buttonTitle={'OK'}
        message={loginError}
      />
    </View>
  );
};

export default LogIn;
