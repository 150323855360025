import { ActionSlice, createActionSlice } from './slices/actions';
import { AnalyticsSlice, createAnalyticsSlice } from './slices/analytics';
import { BrandsSlice, createBrandsSlice } from './slices/brands';
import { GeneralSlice, createGeneralSlice } from './slices/general';
import { HeightSlice, createHeightSlice } from './slices/heights';
import { createFocusedSlice, FocusedSlice } from './slices/isFocused';
import { MarkerSlice, createMarkerSlice } from './slices/markers';
import { MultiScanSlice, createMultiScanSlice } from './slices/multiscan';
import { NotificationMessageSlice, createNotificationMessageSlice } from './slices/notificationMessage';
import { ScoreSlice, createScoreSlice } from './slices/scores';
import { SettingsSlice, createSettingsSlice } from './slices/settings';
import { SleepStatSlice, createSleepStatSlice } from './slices/sleepStat';
import { SocketsSlice, createSocketSlice } from './slices/sockets';
import { TelemetryLogSlice, createTelemetryLogSlice } from './slices/telemetry';
import { UserSlice, createUserSlice } from './slices/user';
import { WearableSdkSlice, createWearableSdkSlice } from './slices/wearableSdk';

import { create } from 'zustand';

export interface ApplicationState
  extends TelemetryLogSlice,
    WearableSdkSlice,
    AnalyticsSlice,
    GeneralSlice,
    UserSlice,
    MultiScanSlice,
    ActionSlice,
    BrandsSlice,
    MarkerSlice,
    SleepStatSlice,
    SettingsSlice,
    NotificationMessageSlice,
    ScoreSlice,
    SocketsSlice,
    HeightSlice,
    FocusedSlice {}

export const useAppStore = create<ApplicationState>()((...a) => ({
  ...createTelemetryLogSlice(...a),
  ...createWearableSdkSlice(...a),
  ...createAnalyticsSlice(...a),
  ...createGeneralSlice(...a),
  ...createUserSlice(...a),
  ...createMultiScanSlice(...a),
  ...createActionSlice(...a),
  ...createBrandsSlice(...a),
  ...createMarkerSlice(...a),
  ...createScoreSlice(...a),
  ...createSleepStatSlice(...a),
  ...createNotificationMessageSlice(...a),
  ...createSettingsSlice(...a),
  ...createSocketSlice(...a),
  ...createHeightSlice(...a),
  ...createFocusedSlice(...a)
}));
