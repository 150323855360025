import Svg, { Path } from 'react-native-svg';
import React from 'react';

const FilledHeartIcon = ({ size = 24, color = '#8F9BB3' }) => (
  <Svg width={`${size}px`} height={`${size}px`} viewBox="0 0 24 24">
    <Path
      fill={color}
      d="M12.001 21.243C11.721 21.243 11.441 21.151 11.222 20.969C5.934 16.662 2.709 13.456 2.709 9.733C2.709 6.934 4.881 4.772 7.655 4.772C9.303 4.772 10.865 5.599 11.999 6.942C13.133 5.599 14.695 4.772 16.343 4.772C19.118 4.772 21.29 6.934 21.29 9.733C21.29 13.456 18.065 16.662 12.777 20.969C12.558 21.151 12.279 21.243 12.001 21.243Z"
    />
  </Svg>
);

export default FilledHeartIcon;
