import React, { useState } from 'react';
import { RegisterOTP, WelcomeAuth } from '../../components/auth';
import { SafeAreaView, StyleSheet } from 'react-native';

import { KeyboardAvoidingView } from '../../components/shared';
import { Layout } from '@ui-kitten/components';
import { RootStackScreenProps } from 'src/core';
import { authStyles } from './_authStyles';
import { confirmationRegisterOTPScreen } from 'src/core/brands';
import { useAppStore } from 'src/core/store';
import { useGetAppName } from 'src/core/hooks/useBrands';
import { useTranslationFunc } from 'src/core/hooks/useTranslationFunc';

const ConfirmationRegisterOTPScreen = ({ navigation }: RootStackScreenProps<'ConfirmationRegisterOTP'>) => {
  const t = useTranslationFunc(confirmationRegisterOTPScreen);
  const appName = useGetAppName();

  const registerEmail = useAppStore((state) => state.registerEmail);
  const [step, setStep] = useState<number>(registerEmail === '' ? 1 : 2);

  // styles
  const styleContainer = StyleSheet.create({
    screenContainer: { flex: 1 },
    safeAreaView: { flex: 1 }
  });

  // handlers and conditionals
  const resetActionClickHandler = () => navigation.navigate('ConfirmationRegister');

  const headerMessages =
    step === 1
      ? {
          title: t('headerMessage1Title', { app_name: appName }),
          welcomeCaption: t('headerMessage1WelcomeCaption')
        }
      : {
          title: t('headerMessage2Title'),
          welcomeCaption: t('headerMessage2WelcomeCaption', { app_name: appName })
        };

  // view
  return (
    <Layout level="2" style={[authStyles.authScreenContainer, styleContainer.screenContainer]}>
      <KeyboardAvoidingView scrollEnabled={false}>
        <SafeAreaView style={styleContainer.safeAreaView}>
          <WelcomeAuth
            title={headerMessages.title}
            welcomeCaption={headerMessages.welcomeCaption}
            style={{ fontSize: 24 }}
          />
          <RegisterOTP
            resetActionClickHandler={resetActionClickHandler}
            registerEmail={registerEmail}
            step={step}
            setStep={setStep}
          />
        </SafeAreaView>
      </KeyboardAvoidingView>
    </Layout>
  );
};

export default ConfirmationRegisterOTPScreen;
