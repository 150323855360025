import { BackHandler, StyleSheet, TouchableOpacity, View, ViewProps } from 'react-native';
import { Layout, ModalService, Text, useTheme } from '@ui-kitten/components';
import { Pillar, UIHelper as uh } from '../../../core';

import AssessmentPillars from '../AssessmentPillars';
import AssessmentScore from './AssessmentScore';
import BarChart from './BarChart';
import BiomarkerDetailModal from '../../profileMeMarker/modal/BiomarkerDetailModal';
import { NewHealthMarker } from 'src/core/types/HealthMarkerReport';
import { ProfileIcons } from 'src/components/profileMe/ProfileIcons';
import React from 'react';
import { toPreciseNumber } from 'src/core/helpers/NumberHelper';
import { useAnalytics } from 'src/core/hooks/useAnalytics';
import FilledHeartIcon from 'src/features/navigation/components/icons/FilledHeartIcon';

//props
interface AssessmentBiomarkerProps extends ViewProps {
  biomarker: NewHealthMarker;
  accessibilityLabel?: string;
  testID?: string;
  showDeleteButton?: boolean;
  onBtnDeleteClick?: () => void;
}

let modalID = '';
const AssessmentBiomarker = ({
  biomarker,
  accessibilityLabel,
  testID,
  showDeleteButton,
  onBtnDeleteClick,
  style
}: AssessmentBiomarkerProps) => {
  const { addAnalyticsLog } = useAnalytics('AssessmentBiomarker.tsx');

  const th = useTheme();
  // styles
  const styleContainer = StyleSheet.create({
    container: {
      marginTop: uh.h2DP(8),
      padding: uh.h2DP(16),
      flexDirection: 'column'
    },
    barChart: {
      marginTop: uh.h2DP(24),
      flex: 1,
      alignItems: 'center'
    },
    icon: {
      width: 36,
      height: 36
    },
    pillarAndScore: {
      flexDirection: 'row',
      marginTop: uh.h2DP(9)
    },
    nameContainer: {
      flexDirection: 'row',
      justifyContent: 'space-between'
    },
    backdrop: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)'
    }
  });

  const chatWithKalibraClick = () => {
    ModalService.hide(modalID);
  };

  React.useEffect(() => {
    const backAction = () => {
      if (modalID != undefined && modalID.length > 0) {
        modalID = ModalService.hide(modalID);
        return true;
      }
      return false;
    };

    const backHandler = BackHandler.addEventListener('hardwareBackPress', backAction);

    return () => backHandler.remove();
  }, []);

  const renderModalContentElement = (data: NewHealthMarker) => {
    // log event MarkerViewed
    addAnalyticsLog({
      data: {
        MarkerId: data.id,
        MarkerDisplayName: data.name
      },
      logType: 'MarkerViewed'
    });

    return (
      <BiomarkerDetailModal
        biomarker={data}
        btnBackHandler={() => {
          ModalService.hide(modalID);
        }}
        btnChatMoreHandler={chatWithKalibraClick}
        btnLearnMoreHandler={chatWithKalibraClick}
        // eslint-disable-next-line
        btnDetailAssessmentClick={(assessmentId) => {
          ModalService.hide(modalID);
        }}
      />
    );
  };

  const showModal = (data: NewHealthMarker) => {
    const contentElement = renderModalContentElement(data);
    modalID = ModalService.show(contentElement, {
      backdropStyle: styleContainer.backdrop
    });
  };

  let pillars: Array<Pillar> = [];
  if (biomarker.pillar != null) {
    pillars = [{ name: biomarker.pillar, type: biomarker.pillar.toLowerCase() }];
  }

  // view
  return (
    <Layout
      style={[styleContainer.container, style]}
      accessible={true}
      accessibilityLabel={accessibilityLabel}
      testID={testID}>
      <TouchableOpacity
        onPress={() => {
          showModal(biomarker);
        }}>
        <View style={styleContainer.nameContainer}>
          <Text category="s1">{biomarker.name}</Text>
          <View style={{ flexDirection: 'row', columnGap: uh.w2DP(16), alignItems: 'center' }}>
            {showDeleteButton && (
              <TouchableOpacity onPress={onBtnDeleteClick}>
                <FilledHeartIcon color={th['color-primary-500']} size={25} />
              </TouchableOpacity>
            )}
            <ProfileIcons.ForwardIcon style={styleContainer.icon} fill={th['color-basic-600']} />
          </View>
        </View>
      </TouchableOpacity>
      <View style={styleContainer.pillarAndScore}>
        <AssessmentScore
          caption={'Value'}
          score={`${toPreciseNumber(biomarker.displayValue)} ${
            biomarker.unit !== 'score'
              ? biomarker.unit
              : `of ${biomarker.maxValue || biomarker.rangeMax?.split('.')[0]}`
          }`}
          textCategory="h5"
        />
        {biomarker.pillar?.length > 0 && (
          <AssessmentPillars caption="Pillar" size="small" showOnePillarOnly={true} pillars={pillars} />
        )}
      </View>
      <BarChart
        currentValue={Number(biomarker.displayValue)}
        healthMarker={biomarker}
        style={styleContainer.barChart}
      />
    </Layout>
  );
};

export default AssessmentBiomarker;
