import { ActionItem } from 'src/core/types/ActionItem';
import { ActionItemService } from '../model';
import { ApiResponse } from 'src/core/types/ApiResponse';
import { BackendApi } from '../../shared';
import { ProtocolItem } from 'src/core/types/Protocols';

export const WebActionItemService: ActionItemService = {
  updateActionCompletedStatus: (id: number, isCompleted: boolean): Promise<void> => {
    return BackendApi.put(`/action-item/user-action/${id}/completed`, {
      completed: isCompleted
    });
  },
  getActions: (): Promise<ActionItem[]> => {
    return BackendApi.get('/action-item/user-action/latest').then((result) => result.data);
  },
  updateAction: (id: number, frequency: number, duration: number): Promise<ActionItem> => {
    return BackendApi.put(`/action-item/user-action/${id}`, {
      repeatFrequencyDays: frequency,
      durationDays: duration
    });
  },
  completeAction: (id: number, completed: boolean): Promise<boolean> => {
    return BackendApi.put(`/action-item/user-action/${id}/completed`, {
      completed: completed
    });
  },
  skipAction: (id: number): Promise<boolean> => {
    return BackendApi.put(`/action-item/user-action/${id}/skip`);
  },
  getNextAvailableAction: (): Promise<ActionItem> => {
    return BackendApi.get('/action-item/user-action/next').then((result) => result.data);
  },
  deleteAction: (id: number): Promise<boolean> => {
    return BackendApi.delete(`/action-item/user-action/${id}`).then((result) => result.data);
  },
  addAction: (id: number): Promise<boolean> => {
    return BackendApi.post('/action-item/user-action', { ids: [id] }).then((result) => result.data);
  },
  getScheduledActiveActions: (): Promise<ActionItem[]> => {
    return BackendApi.get('/action-item/user-action/scheduled/active').then((result) => result.data);
  },
  updateScheduledActiveAction: (id: number, frequency: number, duration: number): Promise<ActionItem> => {
    return BackendApi.put(`/action-item/user-action/scheduled/active/${id}`, {
      repeatFrequencyDays: frequency,
      durationDays: duration
    });
  },
  getScheduledInactiveActions: (): Promise<ActionItem[]> => {
    return BackendApi.get('/action-item/user-action/scheduled/inactive').then((result) => result.data);
  },
  activateInactiveActiveAction: (id: number, frequency: number, duration: number): Promise<ActionItem> => {
    return BackendApi.post(`/action-item/user-action/scheduled/inactive/${id}`, {
      repeatFrequencyDays: frequency,
      durationDays: duration
    });
  },
  deleteScheduledActiveAction: function (id: number): Promise<ActionItem[]> {
    return BackendApi.delete(`/action-item/user-action/scheduled/active/${id}`).then((result) => result.data);
  },
  getAllActions: (): ApiResponse<ActionItem[]> => {
    const abortController = new AbortController();
    return {
      promise: BackendApi.get('/action-item')
        .then((result) => result.data)
        .catch((error) => Promise.reject({ error: error, isAborted: abortController.signal.aborted })),
      abort: () => {
        abortController.abort();
      }
    };
  },
  assignAction: ({
    id,
    durationDays,
    alertTime,
    frequency
  }: {
    id: number;
    durationDays: number;
    alertTime: string;
    frequency: number;
  }): ApiResponse<void> => {
    const abortController = new AbortController();
    return {
      promise: BackendApi.post('/action-item/assign-action', {
        id,
        durationDays,
        alertTime,
        repeatFrequencyDays: frequency
      }),
      abort: () => {
        abortController.abort();
      }
    };
  },
  getAllProtocols: (): ApiResponse<ProtocolItem[]> => {
    const abortController = new AbortController();
    return {
      promise: BackendApi.get('/action-item/protocol')
        .then((result) => result.data)
        .catch((error) => Promise.reject({ error: error, isAborted: abortController.signal.aborted })),
      abort: () => {
        abortController.abort();
      }
    };
  },
  getProtocolDetails: (id: string): ApiResponse<ProtocolItem> => {
    const abortController = new AbortController();
    return {
      promise: BackendApi.get(`/action-item/protocol/${id}`)
        .then((result) => result.data)
        .catch((error) => Promise.reject({ error: error, isAborted: abortController.signal.aborted })),
      abort: () => {
        abortController.abort();
      }
    };
  },
  assignProtocol: (protocolIds: number[]): ApiResponse<void> => {
    const abortController = new AbortController();
    return {
      promise: BackendApi.post('/action-item/assign-protocol', { ids: protocolIds }),
      abort: () => {
        abortController.abort();
      }
    };
  }
};
