import { Button, Divider, Text, useTheme } from '@ui-kitten/components';
import { Config, UIHelper as uh } from '../../../core';
import React, { useState } from 'react';
import { StyleSheet, View } from 'react-native';

import { useGetUserAttributes } from 'src/core/hooks/useUser';

const AppInternals = () => {
  const th = useTheme();
  const [visible, setVisible] = useState<boolean>(false);
  const userAttributes = useGetUserAttributes();

  const styleContainer = StyleSheet.create({
    text: {
      maxHeight: '80%',
      textAlignVertical: 'top',
      marginLeft: uh.w2DP(14)
    },
    caption: { marginBottom: uh.h2DP(4) },
    internals: { minHeight: 50 },
    refreshButton: {
      marginTop: uh.h2DP(10),
      marginBottom: uh.h2DP(10)
    }
  });

  const internalEntry = (internal: [string, string | boolean], censore: boolean, shortCensore: boolean) => {
    return (
      <View key={internal[0]} style={styleContainer.internals}>
        <Text style={styleContainer.caption} category="label" appearance="hint">
          {`${internal[0]}`}
        </Text>
        <Text style={styleContainer.text}>
          {internal[0] === 'SLACK_WEB_HOOK_URL' && internal[1]
            ? `${internal[1].toString().slice(0, 40)}...`
            : censore && internal[1]
              ? `${internal[1].toString().slice(0, 10)}...`
              : shortCensore && internal[1]
                ? `${internal[1].toString().slice(0, 15)}...`
                : `${internal[1]}` || '-'}
        </Text>
      </View>
    );
  };

  const censoredInternals = [
    'COGNITO_USER_POOL_WEB_CLIENT_ID',
    'ONESIGNAL_APP_ID',
    'AMPLITUDE_API_KEY',
    'TERRA_DEV_ID',
    'TERRA_API_KEY',
    'AHI_DEV_MULTISCAN_TOKEN',
    'AHI_DEV_MULTISCAN_AUTHZ_SALT',
    'SPREN_API_KEY'
  ];

  const shortCensore = ['COGNITO_IDENTITY_POOL_ID', 'COGNITO_USER_POOL_ID'];

  return (
    <>
      <View style={{ marginTop: 32 }}>
        <Text
          category={'s1'}
          accessible={true}
          accessibilityLabel="Connected backend label"
          testID="connected_backend_label">
          App Internals
        </Text>
        <Divider
          appearance="default"
          style={{ marginTop: 5, marginBottom: 15, backgroundColor: th['color-primary-500'] }}
        />
      </View>
      {visible && (
        <View style={styleContainer.internals}>
          {Object.entries(Config).map((x) => {
            return internalEntry(x, censoredInternals.includes(x[0]), shortCensore.includes(x[0]));
          })}
          {internalEntry(['USER_COGNITO_ID', userAttributes ? (userAttributes.sub as string) : ''], false, false)}
        </View>
      )}
      <Button
        status="primary"
        onPress={() => setVisible((prev) => !prev)}
        style={styleContainer.refreshButton}
        accessible={true}
        accessibilityLabel="Save developer settings button"
        testID="save_dev_btn">
        {visible ? 'Hide Internals' : 'Show Internals'}
      </Button>
    </>
  );
};

export default AppInternals;
