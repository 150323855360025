import { Platform, SafeAreaView, StyleSheet } from 'react-native';
import { PreSignUp, PreSignUpAlt } from '../../components/auth';

import { KeyboardAvoidingView } from '../../components/shared';
import { Layout } from '@ui-kitten/components';
import React from 'react';
import { RootStackScreenProps } from '../../core';
import { authStyles } from './_authStyles';
import { setValue } from 'src/api/storage';
import { useSetFirstRun } from 'src/core/hooks/useGlobalVar';
import { useIsUserAutoLogin, useUserInitializer, useUserLoginInitializer } from 'src/core/hooks/useUser';

const PreRegisterScreen = ({ navigation }: RootStackScreenProps<'PreRegister'>) => {
  const setFirstLoad = useSetFirstRun();
  useIsUserAutoLogin();
  useUserLoginInitializer();
  useUserInitializer();
  const styleContainer = StyleSheet.create({
    screenContainer: { flex: 1 },
    safeAreaView: { flex: 1 }
  });

  // handlers and conditionals
  const goToRegisterHandler = () => {
    setValue('isFirstLoad', 'true')
      .then(() => {
        setFirstLoad(false);
      })
      .finally(() => {
        navigation.navigate('Login');
      });
  };

  // view
  return (
    <KeyboardAvoidingView>
      <Layout level="2" style={[styleContainer.screenContainer, authStyles.authScreenContainer]}>
        <SafeAreaView style={styleContainer.screenContainer}>
          {Platform.OS === 'web' ? (
            <PreSignUpAlt finalActionHandler={goToRegisterHandler} />
          ) : (
            <PreSignUp finalActionHandler={goToRegisterHandler} />
          )}
        </SafeAreaView>
      </Layout>
    </KeyboardAvoidingView>
  );
};

export default PreRegisterScreen;
