import moment from 'moment';
const isValidNumber = (value: number, minimumValue?: number) => {
  if (minimumValue !== undefined && minimumValue !== null) {
    return value !== null && value !== undefined && typeof value === 'number' && minimumValue > 0;
  }
  return value !== null && value !== undefined && typeof value === 'number';
};

const getAge = (dob: string) => {
  return Math.floor(moment().diff(moment(dob), 'years', true));
};

//Number Helper export functions
export const NumberHelper = {
  isValidNumber: (value: number, minimumValue?: number) => {
    return isValidNumber(value, minimumValue);
  },
  getAge: (dob: string): number => {
    return getAge(dob);
  }
};

export const toPreciseNumber = (value: number | string): string => {
  const numberValue = Number(value);
  if (Number.isInteger(numberValue)) {
    return numberValue.toString();
  }
  return numberValue.toPrecision(3).slice(0, 4);
};

export const toImperialHeight = (value: number): string => {
  const totalInches = value / 2.54;
  const feet = Math.floor(totalInches / 12);
  const inches = Math.round(totalInches % 12);

  return `${feet}ft ${inches}in`;
};

export const toImperialWeight = (value: number): string => {
  const pounds = Math.round(value * 2.20462);
  return `${pounds}`;
};

export const toMetricWeight = (value: number): string => {
  const kg = (value / 2.20462).toFixed(2);
  return `${kg}`;
};

export const numberToArray = (num: number) => {
  return String(num).padStart(3, '0').split('').map(Number);
};

export const kgToArray = (kg: number) => {
  const lbs = Math.round(kg * 2.20462); // Convert kg to lbs (rounded)
  return String(lbs).padStart(3, '0').split('').map(Number);
};

export const formatNumberArray = (arr: number[]) => arr.join('').replace(/^0+/, '');

export function cmToFeetInches(cm: any) {
  // Convert cm to total feet
  const totalFeet = cm / 30.48;

  // Extract whole feet
  const feet = Math.floor(totalFeet);

  // Convert the remaining decimal feet to inches
  const totalInches = (totalFeet - feet) * 12;
  const inches = Math.floor(totalInches);

  // Extract decimal inches
  const decimalInches = Math.round((totalInches - inches) * 10); // Rounded to one decimal place

  return [feet, inches, decimalInches];
}

export function imperialToCm({ feet, inches, decimalInches }: any) {
  const totalInches = feet * 12 + inches + decimalInches / 10;
  return totalInches * 2.54; // Convert inches to cm
}
export function arrayToImperial(arr: any) {
  const [feet, inches, decimalInches] = arr;
  return { feet, inches, decimalInches };
}
export function arrayToString(arr: any) {
  const [feet, inches, decimalInches] = arr;
  return `${feet} ft ${inches}.${decimalInches} in`;
}
export function removeDecimalDigits(num: any) {
  // Convert to string and check if it contains '.'
  if (!num.toString().includes('.')) {
    return num; // Return original value if there's no decimal
  }

  // Split at '.' and return only the integer part
  return Number(num.toString().split('.')[0]);
}
