import React from 'react';
import { View, StyleSheet } from 'react-native';
import { Text, useTheme } from '@ui-kitten/components';
import { UIHelper as uh } from '../../../core';
import { useTranslationFunc } from 'src/core/hooks/useTranslationFunc';

type ProgressBarProps = {
  done: number;
  pillar: string;
};

const styleContainer = StyleSheet.create({
  container: {
    borderRadius: uh.w2DP(18),
    minHeight: uh.h2DP(16),
    height: uh.w2DP(16),
    width: '63%'
  },
  doneText: {
    display: 'flex',
    color: 'white',
    alignItems: 'center',
    marginRight: uh.w2DP(4)
  },
  doneTextWithLowScore: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    position: 'absolute',
    left: uh.w2DP(4),
    width: 30
  },
  line: {
    height: 1,
    position: 'absolute',
    top: 8,
    width: '100%',
    backgroundColor: '#C5CEE0'
  },
  progress: {
    borderRadius: 8,
    flexDirection: 'row-reverse'
  }
});

const MIN_DONE_PERCENT = 12;

const ProgressBar = ({ done, pillar }: ProgressBarProps) => {
  const transMessage = {
    donePercent: '{{done}}%'
  };
  const t = useTranslationFunc(transMessage);
  const th = useTheme();
  const bgColor =
    done <= MIN_DONE_PERCENT
      ? 'transparent'
      : pillar == 'kalibra'
        ? th['color-primary-500']
        : th['color-' + pillar?.toLowerCase() + '-500'];
  const width = done <= MIN_DONE_PERCENT ? uh.w2DP(27) : `${Math.min(done, 100)}%`;

  return (
    <View style={styleContainer.container}>
      <View style={styleContainer.line} />
      <View style={{ ...styleContainer.progress, ...{ width: width, backgroundColor: bgColor } }}>
        <Text
          category="c2"
          style={done > MIN_DONE_PERCENT ? styleContainer.doneText : styleContainer.doneTextWithLowScore}>
          {t('donePercent', { done: done })}
        </Text>
      </View>
    </View>
  );
};

export default ProgressBar;
