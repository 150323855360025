import { Divider, Text, useTheme } from '@ui-kitten/components';
import { LoginVariant, RootNavStackParamList, UIHelper as uh } from 'src/core';
import { Platform, StyleSheet, TouchableOpacity, View, ViewProps } from 'react-native';

import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import React from 'react';
import TextAuthLink from './TextAuthLink';

interface IAuthFooterProps extends ViewProps {
  emailVariant: boolean;
  setEmailVariant: (variant: boolean) => void;
  authVariant: LoginVariant;
  navigation: NativeStackNavigationProp<RootNavStackParamList, 'Register' | 'Login', undefined>;
}

const AuthFooter = (props: IAuthFooterProps): JSX.Element => {
  const th = useTheme();

  const styles = StyleSheet.create({
    authVariant: {
      marginBottom: Platform.OS === 'android' ? 8 : 12
    },
    authVariantText: {
      textAlign: 'center',
      color: th['color-primary-500'],
      marginBottom: props.emailVariant ? 0 : Platform.OS === 'android' ? uh.h2DP(26) : uh.h2DP(18)
    },
    loginAuthLink: {
      marginBottom: uh.h2DP(0)
    },
    registerAuthLink: {
      marginVertical: uh.h2DP(8)
    }
  });

  return (
    <View style={props.style}>
      <Divider style={{ marginBottom: 10, backgroundColor: th['color-primary-500'] }} />
      {props.authVariant === 'Log In' ? (
        <>
          <TouchableOpacity
            style={styles.authVariant}
            onPress={() => props.setEmailVariant(!props.emailVariant)}
            accessible={true}
            accessibilityLabel={`${props.emailVariant ? 'Email' : 'Social'} variant button`}
            testID={`${props.emailVariant ? 'email' : 'social'}_variant_button`}>
            <Text style={styles.authVariantText} category="p2">
              {props.emailVariant ? 'Continue with Google or Apple' : 'You can also continue with email'}
            </Text>
          </TouchableOpacity>
          {/* {props.emailVariant && (
            <TextAuthLink
              message="Don’t have a profile? Sign up instead"
              clickHandler={() => props.navigation.navigate('Register')}
              style={styles.loginAuthLink}
              accessible={true}
              accessibilityLabel="Sign up instead"
              testID="sign_up_instead"
            />
          )} */}
        </>
      ) : (
        <>
          <TextAuthLink
            message="Already have a profile? Log in instead."
            clickHandler={() => props.navigation.navigate('Login')}
            accessible={true}
            accessibilityLabel="Sign In"
            testID="button_login"
          />
          <TextAuthLink
            message="Have a one-time confirmation code to enter?"
            clickHandler={() => props.navigation.navigate('ConfirmationRegisterOTP')}
            style={styles.registerAuthLink}
            accessible={true}
            accessibilityLabel="One-time confirmation"
            testID="button_confirm_otp"
          />
        </>
      )}
    </View>
  );
};

export default AuthFooter;
