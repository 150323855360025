import { StateCreator } from 'zustand';

export interface FocusedSlice {
  isFocused: boolean;
  setIsFocused: (value: boolean) => void;
}

export const createFocusedSlice: StateCreator<FocusedSlice, [], [], FocusedSlice> = (set) => ({
  isFocused: false,

  setIsFocused: (value: boolean) => set(() => ({ isFocused: value }))
});
