import { Layout, useTheme } from '@ui-kitten/components';
import { RootStackScreenProps, UIHelper as uh } from '../../core';
import { SafeAreaView, StyleSheet } from 'react-native';

import { ConfirmAuth } from '../../components/auth';
import { KeyboardAvoidingView } from '../../components/shared';
import React from 'react';
import { authStyles } from './_authStyles';
import { confirmationRegisterScreen } from 'src/core/brands';
import { useAppStore } from 'src/core/store';
import { useGetAppName } from 'src/core/hooks/useBrands';
import { useTranslationFunc } from 'src/core/hooks/useTranslationFunc';

const ConfirmationRegisterScreen = ({ navigation }: RootStackScreenProps<'ConfirmationRegister'>) => {
  // Context
  const th = useTheme();
  const t = useTranslationFunc(confirmationRegisterScreen);
  const appName = useGetAppName();

  const theme = useAppStore((state) => state.theme);
  const condColors = {
    divider: uh.getHex(th, theme, 'color-basic-400', 'color-basic-200'),
    input: uh.getHex(th, theme, 'color-basic-100', 'color-basic-1100')
  };

  // styles
  const styleContainer = StyleSheet.create({
    screenContainer: { flex: 1, paddingTop: uh.h2DP(16) },
    safeAreaView: { flex: 1 },
    input: { backgroundColor: condColors.input }
  });

  //handlers and conditionals
  const btnClickHandler = () => {
    //appContext.signIn(navigation);
    navigation.navigate('Login');
  };

  // view
  return (
    <KeyboardAvoidingView scrollEnabled={false}>
      <Layout level="2" style={[authStyles.authScreenContainer, styleContainer.screenContainer]}>
        <SafeAreaView style={styleContainer.safeAreaView}>
          <ConfirmAuth
            title={t('confirmMessageTitle')}
            caption={t('confirmMessageCaption', { app_name: appName })}
            clickHandler={btnClickHandler}
            buttonTitle={t('confirmMessageButton')}
          />
        </SafeAreaView>
      </Layout>
    </KeyboardAvoidingView>
  );
};

export default ConfirmationRegisterScreen;
