import { Button, Text } from '@ui-kitten/components';
import { LoginVariant, UIHelper as uh } from '../../core';
import { Platform, StyleSheet, View, ViewProps } from 'react-native';
import {
  SocialVariant,
  useIsUserAutoLogin,
  useSetAuthLoading,
  useUser,
  useUserInitializer
  // useUserLoginInitializer
} from 'src/core/hooks/useUser';

import React, { useState } from 'react';
import { SocialIcons as icons } from './AuthIcons';
import { InfoModal } from '../shared/InfoModal';
import { setValue } from 'src/api/storage';

//props
interface ISocialLoginProps extends ViewProps {
  message: string;
  type: LoginVariant;
}

const SocialLogIn = (props: ISocialLoginProps) => {
  const { signInBySocial } = useUser();
  const setAuthLoading = useSetAuthLoading();
  const [visibleErrorModal, setVisibleErrorModal] = useState(false);
  useIsUserAutoLogin();
  // useUserLoginInitializer();
  useUserInitializer();

  // styles
  const styleContainer = StyleSheet.create({
    signUpText: { textAlign: 'center' },
    btnContainer: {
      justifyContent: 'center',
      alignItems: 'center'
    },
    button: {
      minHeight: 0,
      minWidth: 0,
      marginLeft: 16,
      marginRight: 16,
      padding: 0,
      paddingVertical: Platform.OS === 'android' ? 8 : 0,
      marginVertical: 8,
      borderWidth: 0,
      height: uh.h2DP(56),
      width: uh.currentViewPort() - uh.w2DP(56),
      justifyContent: 'center'
    }
  });

  //handlers
  const btnClickHandler = async (socialType: SocialVariant): Promise<void> => {
    setAuthLoading(true);
    try {
      await setValue('isLoadingUserData', 'false');
      await signInBySocial(socialType);
    } catch (err) {
      setVisibleErrorModal(true);
      console.error(`SocialError: ${err}`);
    } finally {
      setAuthLoading(false);
    }
  };

  // View
  return (
    <View style={[props.style]}>
      <View style={styleContainer.btnContainer}>
        {/* <Button
          appearance="filled"
          size="giant"
          status="control"
          style={styleContainer.button}
          accessoryLeft={icons.FacebookIcon}
          accessible={true}
          accessibilityLabel="Facebook"
          testID="button_facebook"
          onPress={() => btnClickHandler(SocialVariant.fbauth)}
        /> */}
        <Button
          appearance="filled"
          size="giant"
          status="control"
          style={styleContainer.button}
          accessoryLeft={icons.GoogleIcon}
          accessible={true}
          accessibilityLabel="Google"
          testID="button_google"
          onPress={() => btnClickHandler(SocialVariant.googauth)}>
          <Text>Continue with Google</Text>
        </Button>
        {/* <Button
          appearance="filled"
          size="giant"
          status="control"
          style={styleContainer.button}
          accessoryLeft={icons.TwitterIcon}
          accessible={true}
          accessibilityLabel="Twitter"
          testID="button_twitter"
          onPress={() => btnClickHandler(SocialVariant.twitauth)}
        /> */}
        <Button
          appearance="filled"
          size="giant"
          status="control"
          style={styleContainer.button}
          accessoryLeft={icons.AppleLightIconCtm}
          accessible={true}
          accessibilityLabel="Apple"
          testID="button_apple"
          onPress={() => btnClickHandler(SocialVariant.appleAuth)}>
          <Text>Continue with Apple</Text>
        </Button>
        {/* <NotWorkingFeatureModal
          visible={visible}
          closeClick={() => {
            setVisible(false);
          }}
        /> */}
      </View>
      <InfoModal
        visible={visibleErrorModal}
        message={'Please check your connection and try again'}
        closeBtnClick={() => {
          setVisibleErrorModal(false);
        }}
      />
    </View>
  );
};

export default SocialLogIn;
