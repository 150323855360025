import { StateCreator } from 'zustand';

export interface HeightSlice {
  feets: number;
  inches: number;
  weight: string;
  height: string;
  unit: number;
  setFeets: (value: number) => void;
  setInches: (value: number) => void;
  setWeight: (value: string) => void;
  setHeight: (value: string) => void;
  setUnit: (value: number) => void;
}

export const createHeightSlice: StateCreator<HeightSlice, [], [], HeightSlice> = (set) => ({
  feets: 5, // Default feet value
  inches: 7, // Default inches value
  weight: '75', // Default inches value
  height: '175', // Default inches value
  unit: 0, // Default unit value

  setFeets: (value: number) => set(() => ({ feets: value })),
  setInches: (value: number) => set(() => ({ inches: value })),
  setWeight: (value: string) => set(() => ({ weight: value })),
  setHeight: (value: string) => set(() => ({ height: value })),
  setUnit: (value: number) => set(() => ({ unit: value }))
});
