import { ResetPassword, WelcomeAuth } from '../../components/auth';
import { SafeAreaView, StyleSheet } from 'react-native';

import { KeyboardAvoidingView } from '../../components/shared';
import { Layout } from '@ui-kitten/components';
import React from 'react';
import { RootStackScreenProps } from '../../core';
import { authStyles } from './_authStyles';
import { resetPasswordScreen } from 'src/core/brands';
import { useGetAppName } from 'src/core/hooks/useBrands';
import { useTranslationFunc } from 'src/core/hooks/useTranslationFunc';

const ResetPasswordScreen = ({ navigation }: RootStackScreenProps<'ResetPassword'>) => {
  const t = useTranslationFunc(resetPasswordScreen);
  const appName = useGetAppName();

  // styles
  const styleContainer = StyleSheet.create({
    screenContainer: { flex: 1 },
    safeAreaView: { flex: 1 }
  });

  //handlers and conditionals
  const goToResetOTPHandler = (email: string) => {
    // replace instead of navigate to be able to go back to home screen
    navigation.replace('PasswordOTP' as any, { email });
  };

  // view
  return (
    <Layout level="2" style={[authStyles.authScreenContainer, styleContainer.screenContainer]}>
      <KeyboardAvoidingView scrollEnabled={false}>
        <SafeAreaView style={styleContainer.safeAreaView}>
          <WelcomeAuth
            title={t('welcomeTitle', { app_name: appName })}
            welcomeCaption={t('welcomeCaption')}
            style={{ fontSize: 24 }}
          />
          <ResetPassword otpHandler={goToResetOTPHandler} />
        </SafeAreaView>
      </KeyboardAvoidingView>
    </Layout>
  );
};

export default ResetPasswordScreen;
