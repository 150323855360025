import { Platform, SafeAreaView, ScrollView } from 'react-native';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { ActionItem } from 'src/core/types/ActionItem';
import ActionItemService from '../../../api/actionItem/index';
import ActionLayout from '../../../features/actions/ActionLayout';
import { ActionScheduleList } from 'src/features/actions/ActionScheduleList';
import { Button } from '@ui-kitten/components';
import DeactivateBottomSheet from 'src/features/actions/DeactivateBottomSheet';
import DisableTenantFeature from 'src/components/shared/DisableTenantFeature';
import { ErrorModal } from 'src/components/shared/ErrorModal';
import RecurrenceBottomSheet from 'src/features/actions/RecurrenceBottomSheet';
import RefreshControl from 'src/components/shared/RefreshControl';
import { RootStackActionProps } from 'src/core/types/RootNavStackParamList';
import Spinner from 'react-native-loading-spinner-overlay';
import { TenantFeatureKey } from 'src/core/types/TenantFeature';
import { mainStyles } from '../_mainStyles';
import moment from 'moment';
import { UIHelper as uh } from 'src/core';
import { useCheckTenantFeature } from 'src/core/hooks/tenant/useCheckTenantFeature';
import { useIsFocused } from '@react-navigation/native';
import { useTranslationFunc } from 'src/core/hooks/useTranslationFunc';

export interface TodayActionsSchedule {
  allDay: ActionItem[][];
  morning: ActionItem[];
  afternoon: ActionItem[];
  evening: ActionItem[];
}

export interface ActionsSchedule {
  yesterday: TodayActionsSchedule;
  today: TodayActionsSchedule;
  tomorrow: TodayActionsSchedule;
  thisWeek: TodayActionsSchedule;
}

const EMPTY_SCHEDULE = {
  allDay: [],
  morning: [],
  afternoon: [],
  evening: []
};

const extractSchedule = (actions: ActionItem[] = []): TodayActionsSchedule => {
  const result: TodayActionsSchedule = {
    allDay: [],
    morning: [],
    afternoon: [],
    evening: []
  };

  const filteredByGroup = actions.filter((action) => actions.filter((a) => a.group === action.group).length > 1);

  const grouped = filteredByGroup.reduce((prev, curr) => {
    return {
      ...prev,
      [curr.group]: [...((prev as any)[curr.group] || []), curr]
    };
  }, {});

  result.allDay = Object.keys(grouped).map((key) => (grouped as any)[key]);

  const restActions = actions.filter((action) => !filteredByGroup.find((fbg) => fbg.id === action.id));

  restActions.forEach((action) => {
    const hr = moment(action.when).hour();
    if (hr < 12) {
      result.morning.push(action);
    } else if (hr < 18) {
      result.afternoon.push(action);
    } else if (hr < 24) {
      result.evening.push(action);
    }
  });

  return result;
};

const groupActionsByDay = (actions: ActionItem[], activeId?: string): ActionsSchedule => {
  const groupedActions = actions.reduce((prev: any, curr: ActionItem) => {
    if (activeId && curr.id.toString() === activeId) {
      curr.active = true;
    }
    if (!prev[`${moment(curr.when).startOf('day').toDate()}`]) {
      prev[`${moment(curr.when).startOf('day').toDate()}`] = [curr];
    } else {
      prev[`${moment(curr.when).startOf('day').toDate()}`].push(curr);
    }
    return prev;
  }, {});

  const dateKeys = Object.keys(groupedActions);

  const yesterdayKey = dateKeys.find((key) => moment(key).isSame(moment(new Date()).subtract(1, 'days'), 'day'));
  const todayKey = dateKeys.find((key) => moment(key).isSame(moment(new Date()), 'day'));
  const tomorrowKey = dateKeys.find((key) => moment(key).isSame(moment(new Date()).add(1, 'days'), 'day'));
  const thisWeekKeys = dateKeys.filter((key) => ![yesterdayKey, todayKey, tomorrowKey].includes(key));

  return {
    yesterday: yesterdayKey ? extractSchedule(groupedActions[yesterdayKey]) : EMPTY_SCHEDULE,
    today: todayKey ? extractSchedule(groupedActions[todayKey]) : EMPTY_SCHEDULE,
    tomorrow: tomorrowKey ? extractSchedule(groupedActions[tomorrowKey]) : EMPTY_SCHEDULE,
    thisWeek: thisWeekKeys.length
      ? {
          morning: thisWeekKeys.reduce((prev: ActionItem[], curr: string) => {
            return [...prev, ...groupedActions[curr]];
          }, []),
          allDay: [],
          evening: [],
          afternoon: []
        }
      : EMPTY_SCHEDULE
  };
};

const TodayActionsScreen = ({ navigation, route: { params } }: RootStackActionProps<'TodayActions'>) => {
  const [refreshing, setRefreshing] = useState<boolean>(false);
  const [actions, setActions] = useState<ActionsSchedule>({
    today: EMPTY_SCHEDULE,
    tomorrow: EMPTY_SCHEDULE,
    thisWeek: EMPTY_SCHEDULE,
    yesterday: EMPTY_SCHEDULE
  });
  const isFocused = useIsFocused();
  const [isLoading, setIsLoading] = useState(false);
  const [currentAction, setCurrentAction] = useState<ActionItem | undefined>(undefined);
  const [errorMessage, setErrorMessage] = useState<any>(null);
  const recurrenceSheetRef = useRef<any>(null);
  const deactivateSheetRef = useRef<any>(null);

  const isActionScreenEnable = useCheckTenantFeature(TenantFeatureKey.CHAT_AND_AGENDA);
  const transMessage = {
    actionsPastDays: 'Past 3 days',
    actionsYesterday: 'Yesterday',
    actionsToday: 'Today',
    actionsTomorrow: 'Tomorrow',
    actionsThisWeek: 'Next 3 days',
    actionsSchedule: 'Schedule',
    actionsEmpty: 'No actions for now',
    actionsAllDay: 'All Day',
    actionsMorning: 'Morning',
    actionsAfternoon: 'Afternoon',
    actionsEvening: 'Evening',
    actionsAdd: 'Add Actions & Protocols'
  };

  const ACTION_ID = params?.actionId;

  const t = useTranslationFunc(transMessage);

  const handleGoToSchedule = (): void => {
    navigation.navigate('ActionsScheduleActive');
  };

  const fetchActionData = useCallback(() => {
    setIsLoading(true);
    ActionItemService.getActions()
      .then((data) => {
        setActions(groupActionsByDay(data, ACTION_ID));
      })
      .catch((error: any) => {
        setActions({
          today: EMPTY_SCHEDULE,
          tomorrow: EMPTY_SCHEDULE,
          yesterday: EMPTY_SCHEDULE,
          thisWeek: EMPTY_SCHEDULE
        });
        setErrorMessage(error);
      })
      .finally(() => {
        setIsLoading(false);
        setRefreshing(false);
      });
  }, [ACTION_ID]);

  useEffect(() => {
    if (refreshing) {
      fetchActionData();
    }
  }, [refreshing, fetchActionData]);

  useEffect(() => {
    if (isFocused && isActionScreenEnable) {
      fetchActionData();
    }
  }, [isFocused, fetchActionData, isActionScreenEnable]);

  const onRefresh = useCallback(() => {
    setRefreshing(true);
  }, []);

  const handleActionChange = (id: number, checked: boolean): void => {
    setIsLoading(true);
    ActionItemService.updateActionCompletedStatus(id, checked)
      .then(() => ActionItemService.getActions())
      .then((data) => {
        setActions(groupActionsByDay(data, ACTION_ID));
      })
      .catch((error: any) => {
        setErrorMessage(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleCompleteAction = (id: number, completed: boolean): void => {
    setIsLoading(true);
    ActionItemService.completeAction(id, completed)
      .then(() => ActionItemService.getActions())
      .then((data) => {
        setActions(groupActionsByDay(data, ACTION_ID));
      })
      .catch((error) => {
        setErrorMessage(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleSkipAction = (id: number): void => {
    setIsLoading(true);
    ActionItemService.skipAction(id)
      .then(() => ActionItemService.getActions())
      .then((data) => {
        setActions(groupActionsByDay(data, ACTION_ID));
      })
      .catch((error) => {
        setErrorMessage(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleUpdateAction = (id: number, frequency: number, duration: number): void => {
    setIsLoading(true);
    ActionItemService.updateAction(id, frequency, duration)
      .catch((error: any) => {
        setErrorMessage(error);
      })
      .finally(() => {
        setIsLoading(false);
        setRefreshing(true);
      });
  };

  if (!isActionScreenEnable) {
    return (
      <ActionLayout>
        <DisableTenantFeature />
      </ActionLayout>
    );
  }

  const handleDeactivate = (action: ActionItem): void => {
    setCurrentAction(action);
    if (!deactivateSheetRef.current) {
      return;
    }
    deactivateSheetRef.current.snapToIndex(0);
    deactivateSheetRef.current.present();
  };

  const handleDeactivateAction = (id: number): void => {
    if (!currentAction) {
      return;
    }
    setIsLoading(true);
    ActionItemService.deleteAction(id).finally(() => {
      setIsLoading(false);
      setRefreshing(true);
    });
  };

  const handleRecurrence = (action: ActionItem): void => {
    setCurrentAction(action);
    if (!recurrenceSheetRef.current) {
      return;
    }
    recurrenceSheetRef.current.snapToIndex(0);
    recurrenceSheetRef.current.present();
  };

  const handleAddActions = () => {
    navigation.navigate('ActionsAdd');
  };

  return (
    <ActionLayout>
      <SafeAreaView style={{ flex: 1 }}>
        <Spinner visible={isLoading && !refreshing} />
        <ScrollView
          contentContainerStyle={[{ flexGrow: 1 }, mainStyles.mainScreenContainer]}
          refreshControl={<RefreshControl refreshing={refreshing} onRefresh={onRefresh} />}>
          <ActionScheduleList
            headerText={t('actionsYesterday')}
            linkLabel={t('actionsSchedule')}
            onLinkLabelClick={handleGoToSchedule}
            commonProps={{
              isFinished: false,
              isEditable: true,
              onChange: handleActionChange,
              onDeactivate: handleDeactivate,
              onRecurrence: handleRecurrence,
              onComplete: handleCompleteAction,
              onSkip: handleSkipAction
            }}
            sections={[
              { headerText: t('actionsAllDay'), actions: actions.yesterday.allDay, simpleList: false },
              { headerText: t('actionsMorning'), actions: actions.yesterday.morning },
              { headerText: t('actionsAfternoon'), actions: actions.yesterday.afternoon },
              { headerText: t('actionsEvening'), actions: actions.yesterday.evening }
            ]}
          />
          <ActionScheduleList
            headerText={t('actionsToday')}
            commonProps={{
              isFinished: false,
              isEditable: true,
              onChange: handleActionChange,
              onDeactivate: handleDeactivate,
              onRecurrence: handleRecurrence,
              onComplete: handleCompleteAction,
              onSkip: handleSkipAction
            }}
            sections={[
              { headerText: t('actionsAllDay'), actions: actions.today.allDay, simpleList: false },
              { headerText: t('actionsMorning'), actions: actions.today.morning },
              { headerText: t('actionsAfternoon'), actions: actions.today.afternoon },
              { headerText: t('actionsEvening'), actions: actions.today.evening }
            ]}
          />
          <ActionScheduleList
            headerText={t('actionsTomorrow')}
            commonProps={{
              isFinished: false,
              isEditable: false,
              onChange: handleActionChange,
              onDeactivate: handleDeactivate,
              onRecurrence: handleRecurrence,
              onComplete: handleCompleteAction,
              onSkip: handleSkipAction
            }}
            sections={[
              { headerText: t('actionsAllDay'), actions: actions.tomorrow.allDay, simpleList: false },
              { headerText: t('actionsMorning'), actions: actions.tomorrow.morning },
              { headerText: t('actionsAfternoon'), actions: actions.tomorrow.afternoon },
              { headerText: t('actionsEvening'), actions: actions.tomorrow.evening }
            ]}
          />
          <ActionScheduleList
            headerText={t('actionsThisWeek')}
            commonProps={{
              isFinished: false,
              isEditable: false,
              onChange: handleActionChange,
              onDeactivate: handleDeactivate,
              onRecurrence: handleRecurrence,
              onComplete: handleCompleteAction,
              onSkip: handleSkipAction
            }}
            sections={[
              {
                headerText: '',
                actions: actions.thisWeek.morning.filter(
                  (action, index) => actions.thisWeek.morning.findIndex((item) => item.name === action.name) === index
                )
              }
            ]}
          />
        </ScrollView>
        <Button
          size="large"
          onPress={handleAddActions}
          style={{
            width: '90%',
            alignSelf: 'center',
            marginTop: uh.h2DP(12),
            marginBottom: Platform.OS === 'android' ? uh.h2DP(12) : 0
          }}
          appearance="outline">
          {t('actionsAdd')}
        </Button>
        <RecurrenceBottomSheet
          sheetRef={recurrenceSheetRef}
          action={currentAction}
          isEditing={true}
          onChange={handleUpdateAction}
        />
        <DeactivateBottomSheet sheetRef={deactivateSheetRef} action={currentAction} onChange={handleDeactivateAction} />
        <ErrorModal
          visible={!!errorMessage}
          message={errorMessage}
          closeBtnClick={() => {
            setErrorMessage(null);
          }}
        />
      </SafeAreaView>
    </ActionLayout>
  );
};

export default TodayActionsScreen;
