import { Button, Input, useTheme } from '@ui-kitten/components';
import { StyleSheet, View, ViewProps } from 'react-native';
import { ValidateError, emailValidator, UIHelper as uh } from '../../core';

import { ErrorMessage } from '../shared';
import React from 'react';
import Spinner from 'react-native-loading-spinner-overlay/lib';
import { requestPasswordReset } from '../../api/auth';
import { useAppStore } from 'src/core/store';

// props
interface IResetPasswordProps extends ViewProps {
  otpHandler: (email: string) => void;
}

const ResetPassword = (props: IResetPasswordProps) => {
  // styles
  const th = useTheme();
  const theme = useAppStore((state) => state.theme);
  const condColors = {
    input: uh.getHex(th, theme, 'color-basic-100', 'color-basic-1100')
  };

  const styleContainer = StyleSheet.create({
    resetPassContainer: { marginTop: uh.h2DP(32) },
    input: { backgroundColor: condColors.input },
    inputEmail: { marginVertical: 16 }
  });

  // properties
  const [email, setEmail] = React.useState<string>('');
  const [error, setError] = React.useState<ValidateError>({ name: '', message: '' });
  const [isSubmitted, setIsSubmitted] = React.useState(false);
  const [loading, setLoading] = React.useState<boolean>(false);

  // handlers and conditionals
  const btnClickHandler = async () => {
    setLoading(true);
    //validate and verify email then call the success redirect function
    const emailError = emailValidator(email);
    if (emailError) {
      setError({ name: 'email', message: emailError });
      setLoading(false);
      return;
    }

    setIsSubmitted(true);
    const result = await requestPasswordReset(email);
    setIsSubmitted(false);
    if (result?.error) {
      setError({ name: '', message: result?.error?.message });
    } else {
      setError({ name: '', message: '' });
      props.otpHandler(email);
    }
    setLoading(false);
  };

  // view
  return (
    <View style={[styleContainer.resetPassContainer, props.style]}>
      {loading && <Spinner visible={true} />}
      <ErrorMessage message={error.message} />
      <Input
        size="large"
        style={[styleContainer.input, styleContainer.inputEmail]}
        label="Email"
        value={email}
        onChangeText={setEmail}
        accessible={true}
        accessibilityLabel={'reset_password_email'}
        testID={'reset_password_email'}
      />
      <Button
        size="giant"
        status="primary"
        onPress={btnClickHandler}
        disabled={isSubmitted}
        accessible={true}
        accessibilityLabel="Reset password"
        testID="button_reset_password">
        Send
      </Button>
    </View>
  );
};

export default ResetPassword;
