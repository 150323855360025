import { Animated, Image, ImageProps } from 'react-native';

import React from 'react';
import logo from '../../../assets/images/brand/logo.png';
import logoLight from '../../../assets/images/brand/logo-light.png';
import { useIsLightColorScheme } from '../hooks/useIsLightColorScheme';

interface LogoProps extends Partial<ImageProps> {
  animated?: boolean;
  animatedProps?: Partial<Animated.AnimatedProps<ImageProps>>;
}
const Logo = ({ animated, animatedProps, ...props }: LogoProps) => {
  const isLightTheme = useIsLightColorScheme();
  if (animated) {
    return (
      <>
        <Animated.Image {...animatedProps} source={isLightTheme ? logo : logoLight} />
      </>
    );
  }
  return (
    <>
      <Image {...props} source={isLightTheme ? logo : logoLight} />
    </>
  );
};

export default Logo;
