import { SafeAreaView, StyleSheet } from 'react-native';

import { ConfirmAuth } from '../../components/auth';
import { KeyboardAvoidingView } from '../../components/shared';
import { Layout } from '@ui-kitten/components';
import React from 'react';
import { RootStackScreenProps } from '../../core';
import { authStyles } from './_authStyles';
import { confirmationResetScreen } from 'src/core/brands';
import { useGetAppName } from 'src/core/hooks/useBrands';
import { useTranslationFunc } from 'src/core/hooks/useTranslationFunc';

const ConfirmationResetScreen = ({ navigation }: RootStackScreenProps<'ConfirmationReset'>) => {
  const t = useTranslationFunc(confirmationResetScreen);
  const appName = useGetAppName();

  // styles
  const styleContainer = StyleSheet.create({
    screenContainer: { flex: 1 },
    safeAreaView: { flex: 1 }
  });

  //handlers and conditionals
  const btnclickHandler = () => {
    //appContext.signIn(navigation);
    navigation.navigate('Login');
  };

  // view
  return (
    <KeyboardAvoidingView scrollEnabled={false}>
      <Layout level="2" style={[authStyles.authScreenContainer, styleContainer.screenContainer]}>
        <SafeAreaView style={styleContainer.safeAreaView}>
          <ConfirmAuth
            title={t('confirmMessageTitle')}
            caption={t('confirmMessageCaption', { app_name: appName })}
            clickHandler={btnclickHandler}
            buttonTitle={t('confirmMessageButton')}
          />
        </SafeAreaView>
      </Layout>
    </KeyboardAvoidingView>
  );
};

export default ConfirmationResetScreen;
