import { BackHandler, Platform, ScrollView, StyleSheet, View } from 'react-native';
import {
  Button,
  Card,
  Datepicker,
  IndexPath,
  Input,
  Modal,
  Radio,
  RadioGroup,
  Text,
  useTheme
} from '@ui-kitten/components';
import { ProfileDataFormError, ProfileDataFormHeight } from 'src/core/types/AuthError';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  Units,
  UserAttributes,
  dateValidator,
  genderTypeValidator,
  heightValidator,
  StringHelper as sh,
  // stringValidator,
  UIHelper as uh,
  weightValidator
  // publicEmailValidator
} from '../../core';
import { cmToFeetInches, numberToArray, removeDecimalDigits, toMetricWeight } from 'src/core/helpers/NumberHelper';
import { useChangeAttributes, useGetDataSharingList, useGetUserAttributes, useUser } from 'src/core/hooks/useUser';
import { useHasMarkersInitError, useIsMarkersInitLoading } from 'src/core/hooks/useMarkers';

import ActivityLevelSelect from '../shared/ActivityLevelSelect';
import { AppleIcon } from 'src/core/icons/appleIcon';
import { BackendApi } from 'src/api/shared';
import { ErrorMessage } from '../shared';
import { ErrorModal } from '../shared/ErrorModal';
import GenderInfoTooltip from '../shared/GenderInfoTooltip';
import { InfoModal } from '../shared/InfoModal';
import ProfilePicture from './ProfilePicture';
import { ProviderName } from 'src/core/types/CognitoUser';
import RefreshControl from '../shared/RefreshControl';
import Spinner from 'react-native-loading-spinner-overlay';
import UserService from 'src/api/user';
import WheelPickerInput from 'src/lib/WheelPickerInput';
import { SocialIcons as icons } from '../auth/AuthIcons';
import moment from 'moment';
import { setValue } from 'src/api/storage';
import { updateUser } from '../../api/auth';
import { useAnalytics } from 'src/core/hooks/useAnalytics';
import { useAppStore } from 'src/core/store';
import { useNavigation } from '@react-navigation/native';
import { useSetFirstRun } from 'src/core/hooks/useGlobalVar';
import { useTranslationFunc } from 'src/core/hooks/useTranslationFunc';
import { Auth } from 'aws-amplify';
import FtWheelPickerInput from 'src/lib/FtWheelPickerInput';
import LbsWheelPickerInput from 'src/lib/LbsWheelPickerInput';
import WheelPickerInputCM from 'src/lib/WheelPickerInputCM';

const providers = {
  Google: 'Google',
  SignInWithApple: 'Apple'
};

enum LogoutVariant {
  DELETE_ACCOUNT,
  DELETE_DATA
}

interface ProfileProps {
  shouldRedirect?: boolean;
}

const Profile = ({ shouldRedirect = false }: ProfileProps) => {
  const th = useTheme();
  const { addAnalyticsLog } = useAnalytics('Profile.tsx');
  const { feets, inches, setFeets, setInches, isFocused, setIsFocused } = useAppStore();

  const theme = useAppStore((state) => state.theme);
  const userSubscriptionPlan = useAppStore((state) => state.userSubscriptionPlan);
  const gender = useMemo(() => ['male', 'female'], []);
  const { deleteUserAccount, getProviderName, signOut } = useUser();
  const setTenantFeatures = useAppStore((state) => state.setTenantFeatures);
  const isMarkersInitLoading = useIsMarkersInitLoading();
  const hasMarkerError = useHasMarkersInitError();

  const navigation = useNavigation();

  const condColors = {
    divider: uh.getHex(th, theme, 'color-basic-400', 'color-basic-200'),
    input: uh.getHex(th, theme, 'color-basic-100', 'color-basic-1100'),
    inputPassword: { marginVertical: uh.h2DP(16) }
  };

  const toImperialHeight = (cm: number): { feet: number; inch: number } => {
    const totalInches = cm / 2.54; // Convert cm to inches
    const feet = Math.floor(totalInches / 12); // Get whole feet
    const inch = Math.round(totalInches % 12); // Get remaining inches

    return { feet, inch }; // Return structured data
  };

  //styles
  const styleContainer = StyleSheet.create({
    container: { flex: 1 },
    scrollView: { flex: 1 },
    themeContainerRadio: { marginTop: uh.h2DP(16), marginBottom: uh.h2DP(10) },
    input: { backgroundColor: condColors.input },
    inputPassword: { marginTop: uh.h2DP(16) },
    saveButton: { marginTop: uh.h2DP(24) },
    deleteButton: { marginTop: uh.h2DP(30), marginBottom: uh.h2DP(100) },
    select: { flex: 1, marginTop: uh.h2DP(16) },
    modal: { marginTop: uh.h2DP(40) },
    backdropStyle: { backgroundColor: 'rgba(0, 0, 0, 0.5)' },
    dropdown: { marginTop: uh.h2DP(16) },
    fieldLabel: { marginTop: 16 },
    wheelPicker: {
      borderColor: th['border-basic-color-4'],
      justifyContent: 'space-between',
      marginTop: uh.h2DP(5)
    }
  });

  //properties
  const [email, setEmail] = useState<string>('');
  const [publicEmail, setPublicEmail] = useState<string>('');
  const [fullname, setFullname] = useState<string>('');
  const [nickname, setNickname] = useState<string>('');
  const [height, setHeight] = useState<string>('');
  const heightValue = Number(feets) + Number(inches);
  const [weight, setWeight] = useState<string>('');
  const [birthdate, setBirthdate] = useState(new Date('1980/01/01'));
  const [error, setError] = useState<ProfileDataFormError>({} as ProfileDataFormError);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [selectedGenderIndex, setSelectedGenderIndex] = useState(0);
  const [visible, setVisible] = useState(false);
  const [refreshing, setRefreshing] = useState<boolean>(false);
  const [visibleConfirmationModal, setVisibleConfirmationModal] = useState(false);
  const [smokeInput, setSmoke] = useState(1);
  const [diabeticInput, setDiabetic] = useState(0);
  const [hypertensionInput, setHypertension] = useState(1);
  const [bloodPressureInput, setBloodPressureInput] = useState(1);
  const [visibleDataModal, setVisibleDataModal] = useState<boolean>(false);
  const [visibleErrorModal, setVisibleErrorModal] = useState<boolean>(false);
  const [visibleLogoutModal, setVisibleLogoutModal] = useState<boolean>(false);
  const [logoutType, setLogoutType] = useState<LogoutVariant>(LogoutVariant.DELETE_DATA);
  const [dataError, setDataError] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const [providerName, setProviderName] = useState<ProviderName>();
  const [selectedActivityLevel, setSelectedActivityLevel] = useState<IndexPath>(new IndexPath(3));
  const [userMergeStatus, setUserMergeStatus] = useState<boolean>(false);
  const [visibleCampaignModal, setVisibleCampaignModal] = useState<boolean>(false);
  const [visibleProfileModal, setVisibleProfileModal] = useState<boolean>(false);
  const [visibleDeleteModal, setVisibleDeleteModal] = useState<boolean>(false);
  const [modalText, setModalText] = useState<string>('');

  const [componentHeights, setComponentHeights] = useState<ProfileDataFormHeight>({} as ProfileDataFormHeight);
  const [unit, setUnit] = useState<Units>(Units.Metric);

  const scrollRef = useRef<ScrollView>(null);
  const inputRefs = useRef<Array<null | View>>([]);

  const setFirstLoad = useSetFirstRun();
  const changeAttributes = useChangeAttributes();
  const userAttributes = useGetUserAttributes();

  const transMessage = {
    labelSex: 'Sex',
    labelActivityLevel: 'Activity Level',
    labelSmoking: 'Smoking',
    labelBloodPressure: 'Are you taking blood pressure medication?',
    labelHypertension: 'Do you have Hypertension?',
    labelDiabetic: 'Are you diabetic?',
    labelHeight: 'Height ({{unit}})',
    labelWeight: 'Weight ({{unit}})',
    diabeticNone: 'None',
    diabeticType1: 'Type 1',
    diabeticType2: 'Type 2',
    yes: 'Yes',
    no: 'No',
    metric: 'Metric',
    imperial: 'Imperial',
    buttonUpdate: 'Update',
    buttonDeleteData: 'Delete all my data',
    buttonDeleteAccount: 'Delete Account',
    messageUpdateSuccessful: 'Profile changes saved successfully!',
    messageMergeSuccessful:
      'Profile changed successfully. Please note that you are sharing data with the tenant. If you no longer wish to share data please go to settings-> data sharing to disable this feature.',
    markersErrorMessage:
      "We couldn't connect with your data. Please check your internet or mobile connection and try again",
    messageLogout: 'You will now be logged out to complete the process.',
    messageDeleteData:
      'Are you sure you want to delete your data? This operation will erase all your data and it is not reversible',
    messageDeleteAccount:
      'Are you sure you want to delete your account? This operation will erase all your data and it is not reversible',
    warningText:
      'You are currently sharing data with at least one organisation. To delete your data, you must first stop sharing in the Data Sharing screen.'
  };

  const t = useTranslationFunc(transMessage);

  const onRefresh = useCallback(() => {
    setRefreshing(true);
  }, []);

  const save = async () => {
    const newError: ProfileDataFormError = {} as ProfileDataFormError;
    let firstValue: keyof ProfileDataFormHeight | '' = ''; // use this value to scroll to the first error

    // if (userAttributes?.email?.includes('privaterelay.appleid.com')) {
    //   const publicEmailError = publicEmailValidator(publicEmail);
    //   if (publicEmailError) {
    //     newError.publicEmail = publicEmailError;
    //     if (firstValue === '') firstValue = 'publicemail';
    //   }
    // }

    // const fullNameError = stringValidator('Full Name', fullname);
    // if (fullNameError) {
    //   newError.fullname = fullNameError;
    //   if (firstValue === '') firstValue = 'fullname';
    // }

    // const nicknameError = stringValidator('Preferred Name', nickname);
    // if (nicknameError) {
    //   newError.nickname = nicknameError;
    //   if (firstValue === '') firstValue = 'nickname';
    // }

    const heightError = heightValidator(height);
    if (heightError) {
      newError.height = heightError;
      if (firstValue === '') firstValue = 'height';
    }
    const weightError = weightValidator(weight);
    if (weightError) {
      newError.weight = weightError;
      if (firstValue === '') firstValue = 'weight';
    }
    const genderError = genderTypeValidator(gender[selectedGenderIndex]);
    if (genderError) {
      newError.gender = genderError;
      if (firstValue === '') firstValue = 'gender';
    }
    const birthdateError = dateValidator('YYYY-MM-DD', moment(birthdate).format('YYYY-MM-DD'));
    if (birthdateError) {
      newError.birthdate = birthdateError;
      if (firstValue === '') firstValue = 'birthdate';
    }

    setError(newError);
    if (Object.values(newError).length > 0) {
      scrollRef.current?.scrollTo({
        y: componentHeights[firstValue as keyof ProfileDataFormHeight] - 200
      });
      return;
    }

    const values: UserAttributes = {
      name: fullname,
      nickname: nickname?.length > 0 ? nickname : fullname,
      gender: gender[selectedGenderIndex],
      birthdate: birthdate ? moment(birthdate).format('YYYY-MM-DD') : undefined
    };

    setIsSubmitted(true);

    // update user's info
    const result = await updateUser(values);
    if (result.error) {
      addAnalyticsLog({ function: 'save', data: result.error, logType: 'error' });
      setDataError(result.error);
      setVisibleErrorModal(true);
      setIsSubmitted(false);
      return;
    }
    let mergeStatus = false;
    try {
      mergeStatus = await UserService.getUserMergeStatus();
      setUserMergeStatus(mergeStatus);

      const newValues = {
        ...values,
        cognitoId: userAttributes.sub,
        email: email,
        publicEmail: publicEmail,
        weight: Number(weight),
        height: unit === Units.Metric ? Number(height) : Math.floor(Number(heightValue)),
        smoker: smokeInput === 0,
        bloodpressureMedication: bloodPressureInput === 0,
        hypertension: hypertensionInput === 0,
        diabetic: diabeticInput,
        activityLevel: selectedActivityLevel.row
      };
      await BackendApi.patch('/users', newValues);
      if (mergeStatus == true) {
        await UserService.updateUserMergeStatus();
      }
      changeAttributes({ ...newValues, sub: userAttributes.sub });
      setValue('isFirstLoad', 'true');
      setFirstLoad(true);
    } catch (err) {
      addAnalyticsLog({ function: 'save', data: err, logType: 'error' });
      setIsSubmitted(false);
      setDataError(err);
      setVisibleErrorModal(true);
      return;
    }

    UserService.getTenantsFeatures()
      .then(async (tenantFeatures) => {
        setTenantFeatures(tenantFeatures);
        setIsSubmitted(false);
        setVisible(true);
      })
      .catch((err) => {
        setIsSubmitted(false);
        addAnalyticsLog({ function: 'save', data: err, logType: 'error' });
        setDataError(err);
        setVisibleErrorModal(true);
      });
  };

  const useGetDataSharingLists = useGetDataSharingList();

  const isTenantActive = useMemo(() => {
    return useGetDataSharingLists?.some((item) => item?.isActive === true);
  }, [useGetDataSharingLists]);

  const isTenantActiveRef = useRef(isTenantActive);

  useEffect(() => {
    isTenantActiveRef.current = isTenantActive;
  }, [isTenantActive]);

  const loadUserData = useCallback(async () => {
    try {
      const userProviderName = await getProviderName();

      if (userProviderName) {
        setProviderName(userProviderName);
      }

      let pEmail = '';
      try {
        const user = await Auth.currentAuthenticatedUser({
          bypassCache: true
        });
        pEmail = user.attributes['custom:public_email'];
      } catch (err) {
        console.error(err);
      }
      setFeets(toImperialHeight(Number(userAttributes.height))?.feet * 30.48);
      setInches(toImperialHeight(Number(userAttributes.height))?.inch * 2.54);
      setHeight(userAttributes.height?.toString() ?? '175');
      setWeight(userAttributes.weight?.toString() ?? '75');
      setSmoke(Number((userAttributes.smoker ?? 0) == 0));
      setBloodPressureInput(Number((userAttributes.bloodpressureMedication ?? 0) == 0));
      setHypertension(Number((userAttributes.hypertension ?? 0) == 0));
      setDiabetic(userAttributes.diabetic ?? 0);
      setFullname(userAttributes.name ?? '');
      setNickname(userAttributes.nickname ?? '');
      setEmail(userAttributes.email ?? '');
      setPublicEmail(pEmail);
      setSelectedGenderIndex(gender.indexOf(String(userAttributes.gender)));
      setBirthdate(moment(userAttributes.birthdate ?? new Date('1980/01/01'), 'YYYY-MM-DD').toDate());
      setSelectedActivityLevel(new IndexPath(userAttributes.activityLevel ?? 0));
      setRefreshing(false);
    } catch (err) {
      addAnalyticsLog({ function: 'loadUserData', data: err, logType: 'error' });
      setRefreshing(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gender, addAnalyticsLog, userAttributes, getProviderName]);

  const getTenants = useCallback(async () => {
    UserService.getTenantsFeatures()
      .then(async (tenantFeatures) => {
        setTenantFeatures(tenantFeatures);
      })
      .catch((err) => {
        setDataError(err);
        setVisibleErrorModal(true);
      });
  }, [setTenantFeatures]);
  const isBackAllowed = useCallback(async () => {
    try {
      const mergeStatus = await UserService.getUserMergeStatus();

      if (shouldRedirect && mergeStatus === false) {
        return true;
      } else {
        return false;
      }
    } catch (err) {
      return false; // Default to false on error
    }
  }, [shouldRedirect]);

  // useEffect(() => {
  //   const backAction = () => {
  //     if (visible == true) {
  //       setVisible(false);
  //       return true;
  //     }
  //     if (visibleConfirmationModal == true) {
  //       setVisibleConfirmationModal(false);
  //       return true;
  //     }

  //     return false;
  //   };
  const backAction: any = useCallback(async () => {
    if (visible) {
      setVisible(false);
      return true; // Prevent default back action
    }
    if (visibleConfirmationModal) {
      setVisibleConfirmationModal(false);
      return true; // Prevent default back action
    }
    const isBack = await isBackAllowed();
    if (isBack) {
      setVisibleProfileModal(true);
      return true; // Prevent default back action
    } else {
      navigation.goBack();
      return false; // Allow default back action
    }
  }, [visible, visibleConfirmationModal, isBackAllowed, navigation]);

  useEffect(() => {
    const backHandler = BackHandler.addEventListener('hardwareBackPress', backAction);
    return () => backHandler.remove();
  }, [visible, visibleConfirmationModal, backAction]);

  useEffect(() => {
    loadUserData();
  }, [loadUserData]);

  // load tenants
  useEffect(() => {
    getTenants();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getTenants]);

  useEffect(() => {
    if (refreshing) {
      loadUserData();
    }
  }, [refreshing, loadUserData]);

  const deleteUser = async () => {
    setLoading(true);
    try {
      const isDeleted = await UserService.deleteUser();
      if (isDeleted) {
        await deleteUserAccount().then(() => {
          setLogoutType(LogoutVariant.DELETE_ACCOUNT);
          setVisibleLogoutModal(true);
        });
      }
    } catch (err) {
      addAnalyticsLog({ function: 'deleteUser', data: err, logType: 'error' });
    }
    setVisibleConfirmationModal(false);
    setLoading(false);
  };

  const deleteData = async () => {
    setLoading(true);
    await BackendApi.post('/users/clean-user-data')
      .then(() => {
        setVisibleDataModal(false);
        setLogoutType(LogoutVariant.DELETE_DATA);
        setVisibleLogoutModal(true);
      })
      .catch((err) => {
        setDataError(err);
        setVisibleDataModal(false);
        setVisibleErrorModal(true);
      });
    setLoading(false);
  };

  const feetItems = Array.from({ length: 4 }, (_, i) => {
    const feet = i + 4;
    return {
      label: feet.toString(),
      value: Number(feet * 30.48) // Convert feet to cm
    };
  });
  const inchesItems = Array.from({ length: 12 }, (_, i) => {
    return {
      label: i.toString(),
      value: Number(i * 2.54) // Convert inches to cm
    };
  });
  const heightFeetUnits = (heightFeets: any) => {
    const imperialHeight = toImperialHeight(Number(heightFeets));
    setFeets(imperialHeight?.feet * 30.48 || feetItems[1].value);
    setInches(imperialHeight?.inch * 2.54 || inchesItems[0].value);
  };

  //view
  return (
    <ScrollView
      style={[styleContainer.container]}
      refreshControl={<RefreshControl refreshing={refreshing} onRefresh={onRefresh} />}
      ref={scrollRef}>
      <Spinner visible={isSubmitted || loading || isMarkersInitLoading} />
      <ProfilePicture />
      {providerName && (
        <>
          <Input
            size="large"
            style={[styleContainer.input]}
            label="Provider"
            value={providers[providerName]}
            disabled={true}
            accessible={true}
            accessoryLeft={
              providerName === 'Google' ? (
                () =>
                  icons.GoogleIcon({
                    style: { height: 24, width: 24, marginLeft: 8 },
                    fill: th['color-basic-disabled']
                  })
              ) : (
                <AppleIcon color={th['color-basic-disabled']} customStyle={{ marginLeft: 8, marginBottom: 2 }} />
              )
            }
            accessibilityLabel="User provider label"
            testID="user_provider_label"
          />
        </>
      )}

      <View
        ref={(el) => (inputRefs.current[0] = el)}
        onLayout={(event) => setComponentHeights({ ...componentHeights, fullname: event.nativeEvent.layout.y })}>
        <Input
          style={[styleContainer.input, styleContainer.inputPassword]}
          size="large"
          status={error.fullname ? 'danger' : 'basic'}
          label="Full Name (provided)"
          value={fullname}
          onChangeText={(text: string) => setFullname(text)}
          maxLength={250}
          accessible={true}
          accessibilityLabel="Full name input"
          disabled={true}
          testID="full_name_input"
        />
      </View>
      <ErrorMessage message={error.fullname} />

      <Input
        size="large"
        style={[styleContainer.input, providerName ? styleContainer.inputPassword : {}]}
        label="Email (provided)"
        value={email}
        disabled={true}
        accessible={true}
        maxLength={250}
        accessibilityLabel="User email label"
        testID="user_email_label"
      />

      {userAttributes.email?.includes('privaterelay.appleid.com') && (
        <>
          <Input
            size="large"
            style={[styleContainer.input, providerName ? styleContainer.inputPassword : {}]}
            label="Public Email (optional)"
            value={publicEmail}
            disabled={false}
            accessible={true}
            onChangeText={(text: string) => setPublicEmail(text)}
            maxLength={250}
            accessibilityLabel="User public email label"
            testID="user_public_email_label"
          />

          {/* <ErrorMessage message={error.publicEmail} /> */}
        </>
      )}

      <View
        ref={(el) => (inputRefs.current[1] = el)}
        onLayout={(event) => setComponentHeights({ ...componentHeights, nickname: event.nativeEvent.layout.y })}>
        <Input
          style={[styleContainer.input, styleContainer.inputPassword]}
          size="large"
          status={error.nickname ? 'danger' : 'basic'}
          value={nickname}
          label="Preferred Name (optional)"
          maxLength={36}
          onChangeText={(text: string) => setNickname(text)}
          accessible={true}
          accessibilityLabel="Preferred Name input"
          testID="pref_name_input"
        />
      </View>
      <ErrorMessage message={error.nickname} />
      {Platform.OS !== 'web' && (
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginTop: 16
          }}>
          <Button
            style={{ width: '45%' }}
            onPress={() => {
              setWeight(Math.round(Number(weight)).toString());
              setUnit(Units.Metric);
            }}
            appearance={unit === Units.Metric ? 'filled' : 'outline'}>
            {t('metric')}
          </Button>
          <Button
            style={{ width: '45%' }}
            onPress={() => {
              heightFeetUnits(height);
              setUnit(Units.Imperial);
            }}
            appearance={unit !== Units.Metric ? 'filled' : 'outline'}>
            {t('imperial')}
          </Button>
        </View>
      )}
      {Platform.OS === 'web' ? (
        <Input
          style={[styleContainer.input, styleContainer.inputPassword]}
          size="large"
          status={error.height ? 'danger' : 'basic'}
          value={height}
          keyboardType="numeric"
          label={'Height (cm)'}
          onChangeText={(text: string) => setHeight(text)}
          accessible={true}
          accessibilityLabel="Height"
          testID="height_input"
        />
      ) : (
        <>
          {unit === Units.Metric ? (
            <WheelPickerInputCM
              onChange={(value) => setHeight(value.toString())}
              value={height}
              backdropStyle={styleContainer.backdropStyle}
              label={t('labelHeight', { unit: 'cm' })}
              labelStyle={styleContainer.fieldLabel}
              inputStyle={[styleContainer.input, styleContainer.wheelPicker]}
              accessibilityLabel="Height"
              testID="height"
              title="cm"
              defaultValues={numberToArray(Number(removeDecimalDigits(Number(height))))}
              labels={['First', 'Second', 'Third']}
            />
          ) : (
            <FtWheelPickerInput
              onChange={(value) => setHeight(value.toString())}
              backdropStyle={styleContainer.backdropStyle}
              label={t('labelHeight', { unit: 'ft' })}
              labelStyle={styleContainer.fieldLabel}
              inputStyle={[styleContainer.input, styleContainer.wheelPicker]}
              accessibilityLabel="Height"
              testID="height"
              defaultValues={cmToFeetInches(Number(height))}
              labels={['First', 'Second', 'Third']}
            />
          )}
        </>
      )}
      <ErrorMessage message={error.height} />

      {Platform.OS === 'web' ? (
        <Input
          style={[styleContainer.input, styleContainer.inputPassword]}
          size="large"
          status={error.weight ? 'danger' : 'basic'}
          value={weight}
          keyboardType="numeric"
          label={`Weight (${unit === Units.Metric ? 'kg' : 'lbs'})`}
          onChangeText={(text: string) => setWeight(text)}
          accessible={true}
          accessibilityLabel="Weight"
          testID="weight_input"
        />
      ) : (
        <>
          {unit === Units.Metric ? (
            <WheelPickerInput
              onChange={(value) => setWeight(value.toString())}
              value={weight}
              backdropStyle={styleContainer.backdropStyle}
              label={t('labelWeight', { unit: 'kg' })}
              labelStyle={styleContainer.fieldLabel}
              inputStyle={[styleContainer.input, styleContainer.wheelPicker]}
              accessibilityLabel="Weight"
              testID="weight"
              title={'kg'}
              labels={['First', 'Second', 'Third']}
              defaultValues={numberToArray(Number(removeDecimalDigits(Number(weight))))}
            />
          ) : (
            <LbsWheelPickerInput
              onChange={(value) => {
                const calculatedWeight = Number(toMetricWeight(Number(value)));
                setWeight(calculatedWeight.toString());
              }}
              value={weight}
              backdropStyle={styleContainer.backdropStyle}
              label={t('labelWeight', { unit: 'lbs' })}
              labelStyle={styleContainer.fieldLabel}
              inputStyle={[styleContainer.input, styleContainer.wheelPicker]}
              accessibilityLabel="Weight"
              testID="weight"
              title={'lbs'}
              labels={['First', 'Second', 'Third']}
              defaultValues={numberToArray(Number(Math.round(Number(weight) * 2.20462)))}
            />
          )}
        </>
      )}
      <ErrorMessage message={error.weight} />

      <View
        ref={(el) => (inputRefs.current[4] = el)}
        onLayout={(event) => setComponentHeights({ ...componentHeights, gender: event.nativeEvent.layout.y })}
        style={[styleContainer.themeContainerRadio]}>
        <View style={{ flex: 1, flexDirection: 'row', alignItems: 'center' }}>
          <Text category="label" appearance="hint">
            {t('labelSex')}
          </Text>
          <GenderInfoTooltip />
        </View>
        <RadioGroup
          style={{ marginTop: 5, flexDirection: 'row' }}
          selectedIndex={selectedGenderIndex}
          onChange={(index) => setSelectedGenderIndex(index)}>
          <Radio status="primary" accessible={true} accessibilityLabel={'gender_male'} testID={'radio_male_btn'}>
            <Text category="label" appearance="hint">
              {sh.capitalize(gender[0])}
            </Text>
          </Radio>
          <Radio status="primary" accessible={true} accessibilityLabel={'gender_female'} testID={'radio_female_btn'}>
            <Text category="c1" appearance="hint">
              {sh.capitalize(gender[1])}
            </Text>
          </Radio>
        </RadioGroup>
      </View>
      <ErrorMessage message={error.gender} />

      <View
        ref={(el) => (inputRefs.current[5] = el)}
        onLayout={(event) => setComponentHeights({ ...componentHeights, birthdate: event.nativeEvent.layout.y })}>
        <Datepicker
          size="large"
          backdropStyle={styleContainer.backdropStyle}
          status={error.birthdate ? 'danger' : 'basic'}
          controlStyle={[styleContainer.input]}
          label="Date of Birth"
          min={new Date('1920-01-01')}
          placement="bottom"
          max={new Date()}
          date={moment(birthdate).isValid() ? birthdate : new Date()}
          onSelect={(nextDate) => setBirthdate(nextDate)}
          accessible={true}
          accessibilityLabel={'date_of_birth'}
          testID={'date_of_birth'}
          onLayout={(event) => setComponentHeights({ ...componentHeights, birthdate: event.nativeEvent.layout.y })}
        />
      </View>
      <ErrorMessage message={error.birthdate} />

      <Text category="label" appearance="hint" style={styleContainer.fieldLabel}>
        {t('Activity Level')}
      </Text>
      <ActivityLevelSelect
        selectedActivityLevel={selectedActivityLevel}
        setSelectedActivityLevel={setSelectedActivityLevel}
      />
      <Text category="label" appearance="hint" style={styleContainer.fieldLabel}>
        {t('labelSmoking')}
      </Text>
      <RadioGroup selectedIndex={smokeInput} onChange={(index) => setSmoke(index)}>
        <Radio status="primary" accessible={true} accessibilityLabel={'smoking_yes'} testID={'smoking_yes'}>
          {t('yes')}
        </Radio>
        <Radio status="primary" accessible={true} accessibilityLabel={'smoking_no'} testID={'smoking_no'}>
          {t('no')}
        </Radio>
      </RadioGroup>

      <Text category="label" appearance="hint" style={styleContainer.fieldLabel}>
        {t('labelBloodPressure')}
      </Text>
      <RadioGroup selectedIndex={bloodPressureInput} onChange={(index) => setBloodPressureInput(index)}>
        <Radio
          status="primary"
          accessible={true}
          accessibilityLabel={'blood_pressure_yes'}
          testID={'blood_pressure_yes'}>
          {t('yes')}
        </Radio>
        <Radio status="primary" accessible={true} accessibilityLabel={'blood_pressure_no'} testID={'blood_pressure_no'}>
          {t('no')}
        </Radio>
      </RadioGroup>

      <Text category="label" appearance="hint" style={styleContainer.fieldLabel}>
        {t('labelHypertension')}
      </Text>
      <RadioGroup selectedIndex={hypertensionInput} onChange={(index) => setHypertension(index)}>
        <Radio status="primary" accessible={true} accessibilityLabel={'hypertension_yes'} testID={'hypertension_yes'}>
          {t('yes')}
        </Radio>
        <Radio status="primary" accessible={true} accessibilityLabel={'hypertension_no'} testID={'hypertension_no'}>
          {t('no')}
        </Radio>
      </RadioGroup>

      <Text category="label" appearance="hint" style={styleContainer.fieldLabel}>
        {t('labelDiabetic')}
      </Text>
      <RadioGroup selectedIndex={diabeticInput} onChange={(index) => setDiabetic(index)}>
        <Radio status="primary" accessible={true} accessibilityLabel={'diabetic_none'} testID={'diabetic_none'}>
          {t('diabeticNone')}
        </Radio>
        <Radio status="primary" accessible={true} accessibilityLabel={'diabetic_type1'} testID={'diabetic_type1'}>
          {t('diabeticType1')}
        </Radio>
        <Radio status="primary" accessible={true} accessibilityLabel={'diabetic_type2'} testID={'diabetic_type2'}>
          {t('diabeticType2')}
        </Radio>
      </RadioGroup>
      <Input
        size="large"
        style={[styleContainer.input, { marginTop: 16 }]}
        label="Subscription"
        value={userSubscriptionPlan}
        disabled={true}
        accessible={true}
        accessibilityLabel="User subscription label"
        testID="subscription"
      />
      <Button
        style={styleContainer.saveButton}
        size="giant"
        status="success"
        disabled={isSubmitted}
        accessible={true}
        accessibilityLabel="Save"
        testID="button_save"
        onPress={() => {
          save();
        }}>
        <Text status="primary" category="s2">
          {t('buttonUpdate')}
        </Text>
      </Button>

      <Button
        size="giant"
        status="danger"
        style={{ marginTop: 10 }}
        onPress={() => {
          if (isTenantActiveRef.current) {
            setModalText(String(t('warningText')));
            setVisibleDeleteModal(true);
          } else {
            setVisibleDataModal(true);
          }
        }}
        accessible={true}
        accessibilityLabel="Delete all my data"
        testID="button_delete_all_data">
        <Text status="primary" category="s2">
          {t('buttonDeleteData')}
        </Text>
      </Button>

      <Button
        size="giant"
        status="danger"
        style={{ marginTop: 10 }}
        onPress={() => {
          if (isTenantActiveRef.current) {
            setModalText(String(t('warningText')));
            setVisibleDeleteModal(true);
          } else {
            setVisibleConfirmationModal(true);
          }
        }}
        accessible={true}
        accessibilityLabel="Delete account"
        testID="button_delete_account">
        <Text status="primary" category="s2">
          {t('buttonDeleteAccount')}
        </Text>
      </Button>

      <Modal
        visible={visible}
        backdropStyle={styleContainer.backdropStyle}
        style={{ maxWidth: '80%' }}
        onBackdropPress={() => {
          setFirstLoad(true);
          setVisible(false);
          setUserMergeStatus(false);
          if (shouldRedirect) {
            navigation.navigate('Home');
          }
        }}>
        <Card>
          <Text>
            {userMergeStatus
              ? t('messageMergeSuccessful', { tenant: userSubscriptionPlan ? userSubscriptionPlan : '' })
              : t('messageUpdateSuccessful')}
          </Text>
          <Button
            onPress={() => {
              setFirstLoad(true);
              setVisible(false);
              setUserMergeStatus(false);
              if (shouldRedirect) {
                navigation.navigate('Home');
                setIsFocused(!isFocused);
              }
            }}
            style={styleContainer.modal}
            accessible={true}
            accessibilityLabel="OK"
            testID="button_ok">
            OK
          </Button>
        </Card>
      </Modal>

      <InfoModal
        message={t('messageDeleteAccount')}
        visible={visibleConfirmationModal}
        buttonTitle={'Yes'}
        secondaryButtonTitle={'Go back'}
        closeBtnClick={deleteUser}
        secondaryButtonClick={() => {
          setVisibleConfirmationModal(false);
        }}
      />
      <InfoModal
        visible={visibleDataModal}
        message={t('messageDeleteData')}
        buttonTitle={'Yes'}
        closeBtnClick={() => deleteData()}
        secondaryButtonClick={() => setVisibleDataModal(false)}
        secondaryButtonTitle={'Go back'}
      />
      <InfoModal
        visible={visibleLogoutModal}
        message={t('messageLogout')}
        buttonTitle={'OK'}
        closeBtnClick={
          logoutType === LogoutVariant.DELETE_DATA
            ? async () => {
                await signOut();
              }
            : async () => {
                await signOut(true);
                setValue('isFirstLoad', 'true');
              }
        }
      />
      <InfoModal
        visible={hasMarkerError}
        message={t('markersErrorMessage')}
        buttonTitle={'OK'}
        closeBtnClick={async () => {
          await signOut();
        }}
      />
      <InfoModal
        visible={visibleCampaignModal}
        message={'Please complete the onboarding to initialise your scores. This is required for the app to work well.'}
        closeBtnClick={() => {
          setVisibleCampaignModal(false);
          navigation.navigate('KaliFirstSignin');
        }}
      />
      <InfoModal
        visible={visibleDeleteModal}
        message={String(modalText)}
        closeBtnClick={() => {
          setVisibleDeleteModal(false);
        }}
      />
      <InfoModal
        visible={visibleProfileModal}
        message={'Please complete your personal profile. This is needed for the app to work well.'}
        closeBtnClick={() => {
          setVisibleProfileModal(false);
        }}
      />

      <ErrorModal visible={visibleErrorModal} message={dataError} closeBtnClick={() => setVisibleErrorModal(false)} />
    </ScrollView>
  );
};

export default Profile;
