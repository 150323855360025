import { Card, Icon, Input, Modal, Text } from '@ui-kitten/components';
import React, { memo, useEffect, useState } from 'react';
import { StyleProp, View, ViewStyle, StyleSheet } from 'react-native';
import { WheelPicker } from 'react-native-ui-lib';
import { numberToArray, toImperialWeight } from 'src/core/helpers/NumberHelper';

import { useAppStore } from 'src/core/store';

interface LbsWheelPickerInputProps {
  onChange: (item: string | number) => void;
  backdropStyle?: StyleProp<ViewStyle>;
  value: string | number;
  label?: string;
  labelStyle?: StyleProp<ViewStyle>;
  inputStyle?: StyleProp<ViewStyle>;
  onLayout?: (event: any) => void;
  accessibilityLabel?: string;
  testID?: string;
  title?: string;
  labels?: string[];
  defaultValues?: number[];
}

const LbsWheelPickerInput = ({
  title = 'Select a Value',
  labels = ['First', 'Second', 'Third'],
  defaultValues = [0, 0, 0],
  onChange,
  backdropStyle,
  label,
  labelStyle,
  inputStyle,
  value,
  onLayout,
  accessibilityLabel,
  testID
}: LbsWheelPickerInputProps) => {
  const [visibleWheel, setVisibleWheel] = useState<boolean>(false);
  const { weight } = useAppStore();
  const [selectedValues, setSelectedValues] = useState<number[]>(
    numberToArray(Number(toImperialWeight(Number(weight))))
  );

  useEffect(() => {
    setSelectedValues(numberToArray(Number(toImperialWeight(Number(weight)))));
  }, [weight]);
  const numbers = [...Array(10).keys()];

  const styles = StyleSheet.create({
    modalTitle: {
      fontSize: 18,
      fontWeight: '600',
      textAlign: 'center',
      marginBottom: 10
    },
    pickerContainer: {
      flexDirection: 'row',
      paddingVertical: 10,
      paddingHorizontal: 20,
      borderRadius: 10,
      alignItems: 'center'
    },
    pickerWrapper: {
      alignItems: 'center'
    },
    picker: {
      width: 80
    },
    selectedValueText: {
      fontSize: 20,
      fontWeight: 'bold',
      textAlign: 'center'
    }
  });

  const handleValueChange = (index: number, Value: number) => {
    const newValues = [...selectedValues];
    newValues[index] = Value;
    setSelectedValues(newValues);
    onChange(newValues.join('')); // Pass concatenated value
  };

  useEffect(() => {
    if (visibleWheel) {
      setSelectedValues([...defaultValues]); // Set default values only when modal opens
    }
  }, [visibleWheel, defaultValues]);

  return (
    <>
      <Modal visible={visibleWheel} backdropStyle={backdropStyle} onBackdropPress={() => setVisibleWheel(false)}>
        <Card>
          {/* Show the selected concatenated value on top */}
          <View
            style={{
              height: 40,
              alignSelf: 'center',
              width: 100,
              backgroundColor: 'rgba(0,0,0,0.2)',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: 4
            }}>
            <Text style={styles.selectedValueText}>{`${toImperialWeight(Number(value))} ${title}`}</Text>
          </View>
          <View style={styles.pickerContainer}>
            {labels.map((Label, index) => (
              <View key={index} style={styles.pickerWrapper}>
                <WheelPicker
                  initialValue={selectedValues[index] ?? defaultValues[index]}
                  items={numbers.map((num) => ({ label: `${num}`, value: num }))}
                  onChange={(val) => handleValueChange(index, val)}
                  style={styles.picker}
                />
              </View>
            ))}
          </View>
        </Card>
      </Modal>

      <View onLayout={onLayout}>
        {label && (
          <Text category="label" appearance="hint" style={labelStyle}>
            {label}
          </Text>
        )}
        <Input
          size="large"
          style={inputStyle}
          disabled
          onPressOut={() => setVisibleWheel(true)}
          maxLength={36}
          status={'basic'}
          accessible={true}
          accessibilityLabel={accessibilityLabel}
          testID={testID}
          value={toImperialWeight(Number(value))}
          accessoryRight={<Icon name={'chevron-down-outline'} onPress={() => setVisibleWheel(true)} />}
        />
      </View>
    </>
  );
};

export default memo(LbsWheelPickerInput);
