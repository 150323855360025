import { Linking, Platform, SafeAreaView, ScrollView, StyleSheet, View } from 'react-native';
import { LogIn, WelcomeAuth } from '../../components/auth';
import React, { useRef, useState } from 'react';
import { RootStackScreenProps, UIHelper as uh } from '../../core';

import AuthFooter from 'src/components/auth/AuthFooter';
import { KeyboardAvoidingView } from 'src/components/shared';
import { Layout } from '@ui-kitten/components';
import { authStyles } from './_authStyles';

const RegisterScreen = ({ navigation }: RootStackScreenProps<'Register'>) => {
  const [emailRegister, setEmailRegister] = useState<boolean>(false);

  // styles
  const styleContainer = StyleSheet.create({
    screenContainer: { flex: 1 },
    safeAreaView: { flex: 1, justifyContent: 'space-between' },
    scroll: {},
    kaliQuote: { marginTop: uh.h2DP(28) },
    socialLogin: { marginVertical: uh.h2DP(12) },
    stdLogin: { paddingTop: uh.h2DP(24) },
    textAuthLink: { marginTop: uh.h2DP(16) },
    oneTimeConfirmation: { marginVertical: uh.h2DP(10) }
  });

  const scrollRef = useRef<ScrollView>(null);

  // handlers and conditionals
  const confirmRegisterHandler = () => navigation.navigate('ConfirmationRegisterOTP');

  const goToPage = (url: string) => {
    if (Platform.OS === 'web') {
      window.open(url, '_blank');
    } else {
      Linking.openURL(url);
    }
  };

  // view
  return (
    <Layout level="2" style={[styleContainer.screenContainer]}>
      <ScrollView style={[{ flex: 1 }, authStyles.authScreenContainer]} ref={scrollRef} bounces={false}>
        <KeyboardAvoidingView extraHeight={200} keyboardOpeningTime={0} scrollEnabled={false}>
          <SafeAreaView style={styleContainer.safeAreaView}>
            <View>
              <WelcomeAuth
                title={'Welcome'}
                welcomeCaption={'Please use the same login method to avoid creating more than one account.'}
              />
            </View>
            <LogIn
              type="Sign Up"
              style={styleContainer.stdLogin}
              confirmRegisterHandler={confirmRegisterHandler}
              goToPage={goToPage}
              accessible={true}
              accessibilityLabel="Sign Up"
              testID="button_signup"
              scrollRef={scrollRef}
            />
            <AuthFooter
              emailVariant={emailRegister}
              setEmailVariant={setEmailRegister}
              authVariant={'Sign Up'}
              navigation={navigation}
              style={{ marginBottom: uh.h2DP(12) }}
            />
          </SafeAreaView>
        </KeyboardAvoidingView>
      </ScrollView>
    </Layout>
  );
};

export default RegisterScreen;
